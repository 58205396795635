import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";

import { actions } from '../../../../accountConfiguration.actions';
import { actions as actionOccurrenceType } from '../../../../../occurrenceType/occurrenceType.actions';

import { OccurrenceTypeForAppList } from "./OccurrenceTypeForAppList";
import { InputSwitch } from "primereact/inputswitch";

const columns = [
    {
        field: "select",
        header: "Exibir no APP",
        columnKey: "oid",
        sortable: true,
        filter: true
    }, {
        field: "photo",
        header: "Foto",
        columnKey: "photo",
        sortable: false,
        filter: false,
    }, {
        field: "closeCargoShipment",
        header: "Fechar Enbarque",
        columnKey: "closeCargoShipment",
        sortable: false,
        filter: false,
    }, {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        filter: true
    }, {
        field: "code",
        header: "Código ocorrência",
        columnKey: "code",
        sortable: true,
        filter: true
    }, {
        field: "description",
        header: "Descrição",
        columnKey: "description",
        sortable: true,
        filter: true
    }, {
        field: "groupType",
        header: "Tipo",
        columnKey: "groupType",
        sortable: false,
        filter: true
    }
]

const mapStateToProps = state => {

    return {
        rows: state.crudOccurrenceTypeState.max,
        first: state.crudOccurrenceTypeState.index,
        order: state.crudOccurrenceTypeState.sortOrder,
        records: state.crudOccurrenceTypeState.records,
        columns,
        sortField: state.crudOccurrenceTypeState.sortField,
        totalRecords: state.crudOccurrenceTypeState.count,
        selectedOccurrenceTypeForApp: state.crudAccountConfigurationState.currentRecord.selectedOccurrenceTypeForApp || [],
    }
}
const mapDispatchToProps = dispatch => {
    return {
        load: () => dispatch(actionOccurrenceType.load()),
        paginate: paginationParams => dispatch(actionOccurrenceType.paginate(paginationParams)),
        sortOrder: sortParams => dispatch(actionOccurrenceType.sortOrder(sortParams)),
        applyFilter: filterParams => {
            dispatch(actionOccurrenceType.setType(null));
            dispatch(actionOccurrenceType.applyFilter(filterParams))
        },
        setSelectedOccurrenceTypeForApp: listOccurrenceType => dispatch(actions.onSetSelectedOccurrenceTypeForApp(listOccurrenceType))

    }
}

class OccurrenceTypeForAppListContainer extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    onPage = (e) => {
        this.props.paginate({
            max: e.rows,
            index: e.first
        })
    }
    onSort = (e) => {
        this.props.sortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }

    onFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            if (e.filters.oid) {
                filters.push({
                    campo: key,
                    valor: parseInt(e.filters[key].value),
                    tipoFiltro: 'IGUAL_A'
                })
            } else {
                filters.push({
                    campo: key,
                    valor: e.filters[key].value,
                    tipoFiltro: 'CONTEM'
                })
            }
        })
        this.props.applyFilter(filters)
    }

    onSelectedOccurrenceTypeForApp = (rowData) => {
        let listOccurrenceType = this.props.selectedOccurrenceTypeForApp || [];

        // Verifica se o item já está na lista de selecionados
        let isSelected = false;
        if (this.props.selectedOccurrenceTypeForApp) {
            for (var occurrenceType of this.props.selectedOccurrenceTypeForApp) {
                if (occurrenceType.oid === rowData.oid) {
                    isSelected = true;
                    break;
                }
            }
        }

        if (isSelected) {
            // Remove o item se já estiver selecionado
            listOccurrenceType = listOccurrenceType.filter(item => item.oid !== rowData.oid);
        } else {
            // Caso contrário, adiciona o item
            listOccurrenceType = [...listOccurrenceType, rowData];
        }

        // adicionando as propriedades na lista 
        // foto requerida  
        // closeCargoShipment

        listOccurrenceType = listOccurrenceType.map(item => ({
            ...item,
            photoRequired: item.photoRequired !== undefined ? item.photoRequired : false,
            closeCargoShipment: item.closeCargoShipment !== undefined ? item.closeCargoShipment : false,
        }))

        this.props.setSelectedOccurrenceTypeForApp(listOccurrenceType);
    }

    onPhotoSwitchChange = (e, rowData) => {
        const isPhotoRequerid = e.value;
        let updatedListOccurrenceType = this.props.selectedOccurrenceTypeForApp.map(item => {
            if (item.oid === rowData.oid) {
                item.photoRequired = isPhotoRequerid;
            }
            return item;
        })
        this.props.setSelectedOccurrenceTypeForApp(updatedListOccurrenceType);
    }


    onCloseCargoShipmentSwitchChange = (e, rowData) => {
        const isCloseCargoShipment = e.value;
        let updatedListOccurrenceType = this.props.selectedOccurrenceTypeForApp.map(item => {
            if (item.oid === rowData.oid) {
                item.closeCargoShipment = isCloseCargoShipment;
            }
            return item;
        })
        this.props.setSelectedOccurrenceTypeForApp(updatedListOccurrenceType);
    }

    render() {

        const oidTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: "center" }} className="id-link">{rowData.oid}</div>
            )
        }

        const codeTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: "center" }} >{rowData.code}</div>
            )
        }


        const groupTypeTemplate = (rowData, column) => {
            if (rowData.groupType === "COMPANY") {
                return (
                    <div>{"Empresa"}</div>
                )
            }
            return (
                <div style={{ textAlign: "center" }}>{"Sistema"}</div>
            )
        }



        const columnsWithSelect = this.props.columns.map(col => {

            const updatedCol = col;

            if (updatedCol.field === 'select') {
                return (
                    <Column
                        key={updatedCol.field}
                        header="Exibir no APP"
                        body={(rowData) => {
                            const checked = this.props.selectedOccurrenceTypeForApp && this.props.selectedOccurrenceTypeForApp.some(item => item.oid === rowData.oid);
                            return (<div style={{ textAlign: "center" }}>
                                <InputSwitch
                                    checked={checked}
                                    onChange={() => this.onSelectedOccurrenceTypeForApp(rowData)}
                                />
                            </div>)

                        }}
                        style={{ width: '10%' }}
                    />
                );
            }

            if (updatedCol.field === 'photo') {
                return (
                    <Column
                        key={updatedCol.field}
                        header="Requer Foto"
                        body={(rowData) => {
                            const photoRequiredChecked = this.props.selectedOccurrenceTypeForApp && this.props.selectedOccurrenceTypeForApp.some(item => {
                                let checked = false
                                if (item.oid === rowData.oid) {
                                    checked = item.photoRequired || false;
                                }
                                return checked
                            })
                            const disabled = !(this.props.selectedOccurrenceTypeForApp && this.props.selectedOccurrenceTypeForApp.some(item => item.oid === rowData.oid));

                            return (<div style={{ textAlign: "center" }}>
                                <InputSwitch
                                    checked={photoRequiredChecked} // verificar a lógica para habiliar ou desabiliar o checkbox
                                    onChange={(e) => this.onPhotoSwitchChange(e, rowData)}
                                    disabled={disabled}
                                />
                            </div>
                            );
                        }}
                        style={{ width: '10%' }}
                    />
                );
            }

            if (updatedCol.field === 'closeCargoShipment') {
                return (
                    <Column
                        key={updatedCol.field}
                        header="Fechar Embarque"
                        body={(rowData) => {
                            const closeCargoShipmentChecked = this.props.selectedOccurrenceTypeForApp && this.props.selectedOccurrenceTypeForApp.some(item => {
                                let checked = false
                                if (item.oid === rowData.oid) {
                                    checked = item.closeCargoShipment || false;
                                }
                                return checked
                            })
                            const disabled = !(this.props.selectedOccurrenceTypeForApp && this.props.selectedOccurrenceTypeForApp.some(item => item.oid === rowData.oid));

                            return (<div style={{ textAlign: "center" }}>
                                <InputSwitch
                                    checked={closeCargoShipmentChecked} // verificar a lógica para habiliar ou desabiliar o checkbox
                                    onChange={(e) => this.onCloseCargoShipmentSwitchChange(e, rowData)}
                                    disabled={disabled}
                                />
                            </div>
                            );
                        }}
                        style={{ width: '10%' }}
                    />
                );
            }

            if (updatedCol.field === 'oid') {
                return (<Column {...updatedCol} key={updatedCol.field} body={oidTemplate} style={{ width: '12%' }} />);
            } else if (updatedCol.field === 'groupType') {
                return (<Column {...updatedCol} key={updatedCol.field} body={groupTypeTemplate} style={{ width: '10%' }} />);
            } else if (updatedCol.field === 'code') {
                return (<Column {...updatedCol} key={updatedCol.field} body={codeTemplate} style={{ width: '20%' }} />);
            }

            return (<Column {...updatedCol} key={updatedCol.field} />);

        })

        return (
            <div className="client-form">
                <div className={this.props.className || "panel-margins-border"}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <h3>Ocorrências a serem exibidas no APP</h3>
                        </div>

                        <OccurrenceTypeForAppList
                            {...this.props}
                            columns={columnsWithSelect}
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                        />

                    </div>
                </div>
            </div >

        )

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OccurrenceTypeForAppListContainer);
