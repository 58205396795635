import React from "react";

import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";

import Toolbar from "../../../components/toolbar/Toolbar";
import { Page } from "../../../components/page/Page";
import { routes } from "../../../constants";
import UpdateDataEntregaPortal from "../components/updateDataEntregaPortal";

import { ORDER_PENDENCE_ACEITE, ORDER_WITH_PENDENCES } from "../../portalCarrier/constantes";

import storage from '../../../services/stoage.service';

import { actions } from "../collectionOrderPortalCarrier.actions";
import { actions as driverActions } from '../../driver/driver.actions';

import { CollectionOrderPortalForm } from "./CollectionOrderPortalForm";
import "./CollectionOrderPortalForm.scss";

const mapStateToProps = store => {
  const { crudCollectionOrderPortalState, crudPortalCarrierState, crudDriverState } = store;
  const link = store.crudPortalCarrierState.link;
  const record = crudCollectionOrderPortalState.currentRecord;
  const orders = crudCollectionOrderPortalState.orders;
  const status = crudCollectionOrderPortalState.status;
  const fileImg = crudCollectionOrderPortalState.fileImg;
  const ordersColumns = crudCollectionOrderPortalState.ordersColumns;
  const equipmentTypes = crudCollectionOrderPortalState.equipmentTypes;
  const currentOrderEdit = crudCollectionOrderPortalState.currentOrderEdit;

  const user = storage.getUser();

  let disabledBtnConfirm = record.status === "NEW" || record.status === "APROVED" ? false : true;

  let disabledBtnReject = record.disabledBtnReject || record.status != "NEW";

  let carrier;
  if (user.carrier) {
    carrier = user.carrier
  }

  if (!user.carrier) {
    disabledBtnConfirm = true;
    disabledBtnReject = true;
  }
  // disabledBtnReject=false
  // disabledBtnConfirm=false

  const { dockScheduling } = record
  const currentScreen = crudPortalCarrierState.currentScreen ? crudPortalCarrierState.currentScreen : "";


  const lookupDriverBase = {
    records: crudDriverState.records,
    columns: crudDriverState.columns
      .filter(col => col.field === 'oid' || col.field === 'documentNumber' || col.field === 'name' || col.field === 'carrier.name')
      .map(col => {
        col = { ...col, sortable: false }
        return (<Column key={col.field} {...col} />)
      }),
    rows: crudDriverState.max,
    first: crudDriverState.index,
    order: crudDriverState.sortOrder,
    sortField: crudDriverState.sortField,
    totalRecords: crudDriverState.count,
    editDisabled: !record.driver
  }

  const lookupDriver = { ...crudCollectionOrderPortalState.lookupDriver, ...lookupDriverBase }

  return {
    link,
    orders,
    record,
    status,
    fileImg,
    lookupDriver,
    currentScreen,
    ordersColumns,
    dockScheduling,
    equipmentTypes,
    currentOrderEdit,
    disabledBtnReject,
    disabledBtnConfirm,
    disableLookupDriver: true,
    errorMessage: crudCollectionOrderPortalState.errorMessage,
    showMessageErro: crudCollectionOrderPortalState.showMessageErro,
    docasHourSchedules: crudCollectionOrderPortalState.docasHourSchedules,
    showModalUploadImg: crudCollectionOrderPortalState.showModalUploadImg,
    carrier
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadCollectionOrder: (link) => dispatch(actions.loadCollectionOrder(link)),
    confirmCollectionOrderHandler: (link, statusCollectionOrder) => dispatch(actions.confirmCollectionOrder(link, statusCollectionOrder)),
    rejectedCollectionOrderHandler: (link) => dispatch(actions.rejectedCollectionOrder(link)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      if (field == "vehicle.plate") {
        //remover tudo, menos letras e digitos
        value = value.replace(/[^a-zA-Z0-9]/g, '');
      }

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },


    loadEquipmentType: (link) => dispatch(actions.loadEquipmentType(link)),
    loadHoursDocks: (link) => dispatch(actions.loadHoursDocks(link)),

    changeEquipmentTypeHandler: (equipmentType) => {
      const { name, oid } = equipmentType;
      const field = "towerIntegration.equipmentType";
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, { name, oid }));
    },
    sendReceipt: data => dispatch(actions.sendReceipt(data)),
    setCodeOrder: order => dispatch(actions.setCodeOrder(order)),
    loadOrdersInCargoShipment: data => dispatch(actions.loadOrdersInCargoShipment(data)),
    setShowModalUploadImg: visible => dispatch(actions.setShowModalUploadImg(visible)),
    changeScheduleDocaHourHandler: dockScheduling => {
      dispatch(actions.setField("dockScheduling", dockScheduling));
    },
    hideMessageErro: () => dispatch(actions.setShowMessageErro(false)),
    changeHandlerImage: (fileImg) => dispatch(actions.changeHandlerImage(fileImg)),
    onChangeDeliveryDate: (deliveryDate) => dispatch(actions.onChangeDeliveryDate(deliveryDate)),
    onChangeObs: (obs) => dispatch(actions.onChangeObs(obs)),
    setDisabledBtnConfirm: (enable) => dispatch(actions.setDisabledBtnConfirm(enable)),
    setDisabledBtnReject: (enable) => dispatch(actions.setDisabledBtnReject(enable)),

    /* DRIVER */
    setDriver: (driver) => {
      const { documentNumber, name } = driver
      dispatch(actions.setField("driver", { documentNumber, name }));
    },
    lookupDriverPaginate: (params) => dispatch(driverActions.paginate(params)),
    lookupDriverSortOrder: (params) => dispatch(driverActions.sortOrder(params)),
    lookupDriverSetVisible: (visible) => dispatch(actions.lookupDriverSetVisible(visible)),
    lookupDriverLoad: (filters) => {
      dispatch(driverActions.setType(null));
      dispatch(driverActions.applyFilter(filters));
    },
    lookupDriverApplyFilter: (filters) => {
      dispatch(driverActions.setType(null));
      dispatch(driverActions.applyFilter(filters));
    },

  }
}

function Check(props) {
  if (!props.show) {
    return (<div style={{ width: "17px" }}></div>)
  }
  return (
    <React.Fragment>
      <div >
        <i className="pi pi-check"></i>
      </div>

    </React.Fragment>

  )
}

class CollectionOrderPortalFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    }
  }

  componentDidMount(props) {
    this.props.loadCollectionOrder(this.props.link);
    const user = storage.getUser();

    if (!user.carrier) {
      this.props.setDisabledBtnConfirm(false);
      this.props.setDisabledBtnReject(false);
    }
  }


  onAcceptedCollectionOrderHandler = () => {
    const statusCollectionOrder = this.props.record.status;
    const index = this.props.link.split("/");
    const data = {
      link: this.props.link,
      index: parseInt(index[index.length - 1]),
      plate: this.props.record.vehicle.plate,
      driver: {
        name: this.props.record.driver.name,
        documentNumber: this.props.record.driver.documentNumber,
      },
      towerIntegration: this.props.record.towerIntegration,
      dockScheduling: this.props.record.dockScheduling
    };
    this.props.confirmCollectionOrderHandler(data, statusCollectionOrder);

  };

  onRejectCollectionOrderHandler = () => {
    const index = this.props.link.split("/");
    const data = {
      link: this.props.link,
      index: parseInt(index[index.length - 1]),
    };
    this.props.rejectedCollectionOrderHandler(data);
  };


  onDropdownEquipmentTypeChange = (e) => {
    this.props.changeEquipmentTypeHandler(e.target.value)
  };

  onDropdownScheduleDocaHourChange = (e) => {

    let dockScheduling = this.props.dockScheduling;

    let dockSelected = e.target.value;

    //verificar se ja foi add 
    for (let index = 0; index < dockScheduling.length; index++) {
      if (dockScheduling[index].sigla == dockSelected.sigla && dockScheduling[index].documentNumber == dockSelected.documentNumber) {
        dockScheduling[index].rangeTime = dockSelected;
        break;
      }
    }
    this.props.changeScheduleDocaHourHandler(dockScheduling)
  };


  onTabChange = (e) => {
    this.setState({ activeIndex: e.index })
    if (this.state.activeIndex == 0) {
      const data = {
        "link": this.props.link,
      }
      this.props.loadOrdersInCargoShipment(data);
    }
  }

  onSetCodeOrder = (order) => {
    this.props.setCodeOrder(order);
  }
  changeHandler = (e) => {
    this.props.changeHandler(e);
  }
  // saveHandler =(e)=>{
  //   this.props.saveHandler(e)
  // }

  showModalUploadImg = (visible) => {
    this.props.setShowModalUploadImg(visible);
  }

  onChangeDeliveryDate = (event) => {
    this.props.onChangeDeliveryDate(event.target.value)
  }
  onChangeObs = (event) => {
    this.props.onChangeObs(event.target.value)
  }
  searchHandler = () => {

    if (this.props.currentScreen == ORDER_PENDENCE_ACEITE) {
      this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/open-collection-orders`);
    } else if (this.props.currentScreen == ORDER_WITH_PENDENCES) {
      this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/open-collection-orders-pendences`);
    } else {
      this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`);
    }
  }


  /* DRIVER */
  lookupDriverOnEdit = () => { }
  lookupDriverOnComplete = (e) => { }
  lookupDriverOnInputFilter = (e) => {
    const value = e.value;
    let filters = []
    if (typeof value === 'string') {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      });
      this.props.setDriver({ name: value });
    } else {
      this.props.setDriver(value);
    }
    this.props.lookupDriverApplyFilter(filters);
  }

  lookupDriverOnClick = () => {

    const filters = [];
    filters.push({
      campo: "carrier.cnpj",
      valor: this.props.record.carrierDocumentNumber,
      tipoFiltro: 'IGUAL_A'
    }) 
    this.props.lookupDriverPaginate({
      max: 15,
      index: 0
    });
    this.props.lookupDriverApplyFilter(filters);
    this.props.lookupDriverSetVisible(true);
  }

  lookupDriverOnHide = () => {
    this.props.lookupDriverSetVisible(false);
  }
  lookupDriverOnConfirm = (event) => {
    this.props.setDriver(event.selection);
    this.props.lookupDriverSetVisible(false);
  }
  lookupDriverOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });

    filters.push({
      campo: "carrier.cnpj",
      valor: this.props.record.carrierDocumentNumber,
      tipoFiltro: 'IGUAL_A'
    })

    this.props.lookupDriverApplyFilter(filters);
  }
  lookupDriverOnSort = (e) => {
    this.props.lookupDriverSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }
  lookupDriverOnPage = (e) => {
    this.props.lookupDriverPaginate({
      max: e.rows,
      index: e.first
    });
  }

  render() {

    const viewButtonUpload = (order) => {
      return (
        <div className="lxActionButtons">
          <button
            className="lognex-btn-highlighted"
            onClick={(e) => {
              this.onSetCodeOrder(order);
              this.showModalUploadImg(true);
            }}
            title={"Enviar comprovante de entrega"}
          >
            <i className="pi pi-upload"></i>
          </button>
        </div>
      )
    }

    const imageTemplate = (rowData, column) => {
      let button = viewButtonUpload(rowData)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Check show={rowData.hasImage} />
          <div style={{ width: "40px" }}>
            {button}

          </div>
        </div>
      )
    }
    let ordersColumn = this.props.ordersColumns.map((col, index) => {
      const key = `${index}-${col.field}`;
      if (col.field === "image") {
        return (<Column {...col} style={{ textAlign: "center" }} key={key} body={imageTemplate} />)
      }
      return (<Column {...col} style={{ textAlign: "center" }} key={key} field={col.field} />)
    })

    const lookupDriver = {
      fields: {
        editDisabled: true,
        ...this.props.lookupDriver,
      },
      actions: {
        onSort: this.lookupDriverOnSort,
        onEdit: this.lookupDriverOnEdit,
        onHide: this.lookupDriverOnHide,
        onPage: this.lookupDriverOnPage,
        onClick: this.lookupDriverOnClick,
        onCancel: this.lookupDriverOnHide,
        onFilter: this.lookupDriverOnFilter,
        onConfirm: this.lookupDriverOnConfirm,
        onComplete: this.lookupDriverOnComplete,
        onInputFilter: this.lookupDriverOnInputFilter,
        disabled: !this.props.disableLookupDriver
      }
    }

    const content = () => {
      if (this.props.status === 'SUCCESS_LOAD' || this.props.status === 'FAIL_REJECT' || this.props.status === 'FAIL_CONFIRM') {

        return (
          <div>
            <CollectionOrderPortalForm
              orders={this.props.orders}
              record={this.props.record}
              columns={ordersColumn}
              activeIndex={this.state.activeIndex}
              onTabChange={this.onTabChange}
              lookupDriver={lookupDriver}
              changeHandler={this.props.changeHandler}
              dockScheduling={this.props.dockScheduling}
              equipmentTypes={this.props.equipmentTypes}
              onRejectHandler={this.onRejectCollectionOrderHandler}
              onAcceptedHandler={this.onAcceptedCollectionOrderHandler}
              disabledBtnReject={this.props.disabledBtnReject}
              disabledBtnConfirm={this.props.disabledBtnConfirm}
              docasHourSchedules={this.props.docasHourSchedules}
              onDropdownEquipmentTypeChange={this.onDropdownEquipmentTypeChange}
              onDropdownScheduleDocaHourChange={this.onDropdownScheduleDocaHourChange}
            />
          </div>
        )
      } else if (this.props.status === 'FAIL_LOAD') {
        return (
          <div className="collectionOrder-loadDanger">
            <h1>Ordem de coleta NÃO ENCONTRADA</h1>
          </div>
        )

      } else if (this.props.status === 'SUCCESS_CONFIRM') {
        return (
          <div className="collectionOrder-success">
            <h1>Confirmação da ordem de coleta recebida com sucesso.</h1>
          </div>
        )
      } else if (this.props.status === 'SUCCESS_REJECT') {
        return (
          <div className="collectionOrder-success">
            <h1>Cancelamento da ordem de coleta recebida com sucesso.</h1>
          </div>
        );
      }
    }

    const header = (
      <div>
        <div>
          <Toolbar onSearch={this.searchHandler} />
        </div>
      </div>
    );

    return (


      <div>
        {this.props.showMessageErro &&
          <Dialog
            modal={true}
            style={{ width: "320px" }}
            header={"Atenção"}
            onHide={this.props.hideMessageErro}
            visible={this.props.showMessageErro}
          >
            <h2 className="message-erro">{this.props.errorMessage}</h2>
          </Dialog>
        }

        {this.props.showModalUploadImg &&
          <UpdateDataEntregaPortal />
        }
        <Page header={header} content={content()} />

      </div>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionOrderPortalFormPageContainer);
