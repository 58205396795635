import React from 'react';

import CercaVirtualFormPageContainer from './CercaVirtualMapPageContainer';

export class CercaVirtualFormPage extends React.Component {
    render() {
        return (
            <CercaVirtualFormPageContainer {...this.props} />
        )
    }
}