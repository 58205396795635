import React from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { TableVehicles } from "./TableVehicles";
import { numberToCurrency } from "../../../../utils";
import { getCDsFromRouter } from "../../utils";

export default class ModalChangeVehicle extends React.Component {
  constructor(props) {
    super(props);
  }
  onSelecteVehicleType = (e) => {
    this.props.setVehicleToChange(e.value);
  };
  rowClickHandler = (e) => {
    this.props.setVehicleToChange(e.data);
  };
  render() {
    const footer = (
      <div>
        <ButtonDialog
          onClick={() => this.props.onConfirm()}
          button={{
            title: "Confirmar",
            disabled: !this.props.vehicleSelected,
            className: "lognex-btn-highlighted",
            iconClassName: "pi pi-check",
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente alterar o tipo de veículo na rota?",
          }}
        ></ButtonDialog>

        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onHide}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    // vehiclesDriverAvailable
    console.log("vehiclesDriverAvailable:", this.props.vehiclesDriverAvailable);
    //   {
    //     "plate": "FS2K89",
    //     "numberRenavam": "",
    //     "driver": {
    //         "name": "ALINE MOT SANTIL",
    //         "userId": 416,
    //         "documentNumber": "123456789012",
    //         "dateValidateCNH": "2024-10-10T03:00:00.000Z",
    //         "carrier": {
    //             "oid": 1471,
    //             "name": "SANTIL COMERCIAL ELETRICA LTDA",
    //             "cnpj": "49474398000863"
    //         },
    //         "eventRecord": {
    //             "active": true,
    //             "createdAt": "2024-10-10T13:27:07.088Z",
    //             "updatedAt": "2024-11-07T17:32:01.957Z",
    //             "inactivatedAt": null
    //         },
    //         "virtualFenceCurrent": {
    //             "oid": 25,
    //             "name": "regiao5",
    //             "type": "VIRTUAL_FENCE",
    //             "includeDate": "2024-11-07T14:30:51.771918625-03:00"
    //         },
    //         "virtualFenceHistory": [
    //             {
    //                 "oid": 25,
    //                 "name": "regiao5",
    //                 "type": "VIRTUAL_FENCE",
    //                 "includeDate": "2024-11-07T14:30:51.771918625-03:00"
    //             }
    //         ],
    //         "oid": 3559
    //     },
    //     "vehicleType": {
    //         "name": "KOMBI",
    //         "label": "KOMBI",
    //         "averageSpeed": 60,
    //         "pallets": 6,
    //         "weight": 600,
    //         "eixos": 2,
    //         "oid": 13
    //     },
    //     "vehicleCapacity": 14000,
    //     "eventRecord": {
    //         "active": true,
    //         "createdAt": "2024-10-10T10:17:24.354Z",
    //         "updatedAt": "2024-11-27T19:24:29.411Z",
    //         "inactivatedAt": null
    //     },
    //     "cargoShipment": null,
    //     "cargoShipmentHistory": [
    //         {
    //             "id": 20033,
    //             "createdAt": "2024-11-27T16:24:01.882982552-03:00",
    //             "dateFinish": null,
    //             "virtualFenceName": "regiao1",
    //             "virtualFenceId": 8,
    //             "plate": "FS2K89"
    //         }
    //     ],
    //     "virtualFenceCurrent": {
    //         "oid": 8,
    //         "name": "regiao1",
    //         "type": "VIRTUAL_FENCE",
    //         "includeDate": "2024-11-27T16:24:01.882982552-03:00"
    //     },
    //     "virtualFenceHistory": [
    //         {
    //             "oid": 8,
    //             "name": "regiao1",
    //             "type": "VIRTUAL_FENCE",
    //             "includeDate": "2024-11-27T16:24:01.882982552-03:00"
    //         }
    //     ],
    //     "oid": 3692
    // }

    const templateCustoKm = (rowData, e) => {
      return <span>{numberToCurrency(parseFloat(rowData.custoKm), "R$")}</span>;
    };

    const templateVehicleAvailable = (rowData, e) => {
      if (!rowData.vehicleAvailables || rowData.vehicleAvailables.length == 0) {
        return <div style={{ width: "100%", background: "#FFF", color: "red", height: "100%" }}>
          Não há veículos disponíveis
        </div>;
      }
      return <div style={{ width: "100%", background: "#FFF", color: "green", height: "100%", overflow: "clip" }}>
        {rowData.vehicleAvailables.map(va => va.plate).join(", ")}
      </div>;
    };

    let columns = [<Column key="selectorColumnVehicleType" selectionMode="single" style={{ width: "3em" }} />];

    const columnsVehicle = [...this.props.columns];
    if (this.props.useVirtualFense && this.props.vehiclesDriverAvailable && this.props.vehiclesDriverAvailable.length > 0) {
      columnsVehicle.push(
        {
          field: "vehicleAvailables",
          header: "Placas Disponíveis",
          columnKey: "vehicleAvailables",
        });
    }

    columns = [
      ...columns,
      ...columnsVehicle.map((col) => {
        if (col.field == "custoKm") {
          return <Column {...col} key={col.field} body={templateCustoKm} style={{ width: "120px" }} />;
        }
        if (col.field == "cd") {
          return <Column {...col} key={col.field} style={{ width: "70px" }} />;
        }
        if (col.field == "weight") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field == "pallets") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field == "vehicleAvailables") {
          return <Column {...col} key={col.field} body={templateVehicleAvailable} />;
        }

        return <Column {...col} key={col.field} />;
      }),
    ];

    //mostrar somente veiculos que são dos CDs das Ordens
    let vehicles = this.props.vehicles;
    const listCds = getCDsFromRouter(this.props.routeEditVehicle);

    //filtrar veiculos por CD
    vehicles = vehicles.filter((v) => listCds.indexOf(String(v.cd).trim()) > -1);

    if (this.props.useVirtualFense && this.props.vehiclesDriverAvailable && this.props.vehiclesDriverAvailable.length > 0) {
      vehicles = vehicles.map((v) => {
        const vehicleAvailables = this.props.vehiclesDriverAvailable.filter((vd) => vd.vehicleType.label == v.code);
        return { ...v, vehicleAvailables };
      });
    }

    let isDevolucao = false;
    for (let index = 0; index < this.props.routeEditVehicle.shipments.length; index++) {
      if (this.props.routeEditVehicle.shipments[index].dv && this.props.routeEditVehicle.shipments[index].dv == "dv") {
        isDevolucao = true
        break
      }
    }
    if (isDevolucao) {
      let vehiclesAux = []//deixar somente u
      for (let index = 0; index < vehicles.length; index++) {
        if (vehiclesAux.filter(va => va.cd === vehicles[index].cd && va.code === vehicles[index].code).length == 0) {
          const vehicle = { ...vehicles[index], label: "Devolução" }
          vehiclesAux.push(vehicle);
        }
      }
      vehicles = vehiclesAux
    }
    vehicles.sort((a, b) => {
      return a.pallets - b.pallets;
    });

    return (
      <Dialog
        className="content"
        header={"Alterar o tipo de veículo da rota"}
        footer={footer}
        visible={this.props.visible}
        modal={true}
        onHide={this.props.onHide}
        style={this.props.style || { width: "60vw" }}
      >
        <div style={{ minHeight: "300px" }}>
          <h3>Selecione um veículo para o CD e depósito!</h3>
          <TableVehicles
            records={vehicles}
            columns={columns}
            selectedRow={this.props.vehicleSelected}
            rowClickHandler={this.rowClickHandler}
            onSelectedEventsChange={this.onSelecteVehicleType}
          />
        </div>
      </Dialog>
    );
  }
}
