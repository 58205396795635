import { types } from "./driver.actions";
import { Reducer } from "../../services/reducer.factory";



const initialState = {
    currentRecord: {
        oid: "",
        name: "",
        code: "",
        password: "",
        documentNumber: "",
        dateValidateCNH: "",
        carrier: {
            cnpj: "",
            name: "",
        },
        virtualFenceCurrent: {
            oid: "",
            name: "",
            type: "",
            includeDate: ""
        },
        virtualFenceHistory: [],
        cargoShipment: "",
        cargoShipmentHistory: [],
    },
    currentRecordDefault: {
        oid: "",
        name: "",
        code: "",
        password: "",
        documentNumber: "",
        dateValidateCNH: "",
        carrier: {
            cnpj: "",
            name: "",
        },
        virtualFenceCurrent: {
            oid: "",
            name: "",
            type: "",
            includeDate: ""
        },
        virtualFenceHistory: [],
        cargoShipment: "",
        cargoShipmentHistory: [],
    },
    columns: [
        {
            field: "oid",
            header: "Código",
            columnKey: "oid",
            sortable: false,
            filter: true
        },
        {
            field: "name",
            header: "Nome",
            columnKey: "name",
            sortable: false,
            filter: true
        },
        {
            field: "documentNumber",
            header: "Número do documento",
            columnKey: "documentNumber",
            sortable: false,
            filter: true
        },
        {
            field: "carrier.cnpj",
            header: "Transp CNPJ",
            columnKey: "carrier.cnpj",
            sortable: false,
            filter: true
        },
        {
            field: "carrier.name",
            header: "Transportadora",
            columnKey: "carrier.name",
            sortable: false,
            filter: true
        },
        {
            field: "cargoShipment",
            header: "Embarque",
            columnKey: "cargoShipment",
            sortable: false,
            filter: true
        },
        {
            field: "virtualFenceCurrent.oid",
            header: "Código",
            columnKey: "virtualFenceCurrent.oid",
            sortable: false,
            filter: true
        },
        {
            field: "virtualFenceCurrent.name",
            header: "Nome",
            columnKey: "virtualFenceCurrent.name",
            sortable: false,
            filter: true
        },
        {
            field: "virtualFenceCurrent.includeDate",
            header: "Data",
            columnKey: "virtualFenceCurrent.includeDate",
            sortable: false,
            filter: true
        },
    ],
    virtualFenceHistoryColumns: [
        {
            field: "oid",
            header: "Código",
            columnKey: "oid",
            sortable: false,
            filter: false
        },
        {
            field: "name",
            header: "Nome",
            columnKey: "name",
            sortable: false,
            filter: false
        },
        {
            field: "type",
            header: "Tipo",
            columnKey: "type",
            sortable: false,
            filter: false
        },
        {
            field: "plate",
            header: "Placa",
            columnKey: "plate",
            sortable: false,
            filter: false
        },
        {
            field: "label",
            header: "Tipo Veículo",
            columnKey: "label",
            sortable: false,
            filter: false
        },
        {
            field: "includeDate",
            header: "Data de inclusão",
            columnKey: "includeDate",
            sortable: false,
            filter: false
        },
    ],
    vehicleColumns: [
        {
            field: "plate",
            header: "Placa",
            columnKey: "plate",
            sortable: false,
            filter: false
        },
        {
            field: "vehicleType.name",
            header: "Nome",
            columnKey: "vehicleType.name",
            sortable: false,
            filter: false
        },
        {
            field: "vehicleType.label",
            header: "Tipo Veículo",
            columnKey: "vehicleType.label",
            sortable: false,
            filter: false
        },
        {
            field: "vehicleCapacity",
            header: "Capacidade",
            columnKey: "vehicleCapacity",
            sortable: false,
            filter: false
        },
    ],
    historyCargoShipmentColumns: [
        {
            field: "virtualFenceName",
            header: "Cerca virtual",
            columnKey: "virtualFenceName",
            sortable: false,
            filter: false
        },
        {
            field: "virtualFenceId",
            header: "ID cerca virtual",
            columnKey: "virtualFenceId",
            sortable: false,
            filter: false
        },
        {
            field: "plate",
            header: "Placa",
            columnKey: "plate",
            sortable: false,
            filter: false
        },
        {
            field: "createdAt",
            header: "Data de Criação",
            columnKey: "createdAt",
            sortable: false,
            filter: false
        },
        {
            field: "dateFinish",
            header: "Data de Finalização",
            columnKey: "dateFinish",
            sortable: false,
            filter: false
        },
        {
            field: "status",
            header: "Observação",
            columnKey: "status",
            sortable: false,
            filter: false
        },
    ],
    recordExpand: null,
    vehicles: [],
}

const reducer = new Reducer(initialState, types);

export function CRUDDriverReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        case types.SET_USER_ID:
            return setUserId(state, action)
        case types.SET_VALIDATE_CNH:
            return setValidateCNH(state, action)
        case types.SET_NEW_CLEAR_DRIVER:
            return clearDriverSetNew(state, action)
        case types.SET_RECORD_EXPAND:
            return setRecordExpand(state, action)
        case types.SET_VEHICLES:
            return setVehicles(state, action)

        default:
            result = state;
            break;
    }
    return result;
}

function setVehicles(state, action) {
    let vehicles = action.vehicles;
    return { ...state, vehicles }
}

function setRecordExpand(state, action) {
    let recordExpand = action.recordExpand;
    return { ...state, recordExpand }
}


function clearDriverSetNew(state, action) {
    let currentRecord = { ...state.currentRecordDefault };
    return { ...state, currentRecord }
}

function setValidateCNH(state, action) {
    let currentRecord = state.currentRecord;
    currentRecord.dateValidateCNH = action.date;
    return { ...state, currentRecord }
}


function setUserId(state, action) {
    let currentRecord = state.currentRecord;
    currentRecord.userId = action.userId;
    return { ...state, currentRecord }
}

