import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import './OccurrenceTypeForm.scss'

export const OccurrenceTypeForm = (props) => {
    return (
        <div className="OccurrenceType-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-4 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label >Tipo Componente</label>
                            <p className="lxGroupTypeLabel">{props.groupType}</p>
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Código da ocorrência</label>
                            <InputText value={props.record.code} onChange={props.changeHandler} name="code" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Descrição</label>
                            <InputText value={props.record.description} onChange={props.changeHandler} name="description" disabled={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}