import React from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

import moment from 'moment';

import { InputPassword } from '../../../components/input-password/InputPassword';

import './DriverForm.scss';

export const DriverForm = (props) => {
    const record = props.record;

    let validatedCNH = record.dateValidateCNH && record.dateValidateCNH !== "" ? moment(record.dateValidateCNH).toDate() : null;

    return (
        <div className="driver-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={record.oid || ""} onChange={(e) => {
                                    e.stopPropagation();
                                    props.changeHandler(e);
                                }} disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={record.name || ""} onChange={(e) => {
                                e.stopPropagation();
                                props.changeHandler(e);
                            }} name="name" autoComplete="new-password" />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">CPF / CNPJ</label>
                            <InputText value={record.documentNumber || ""} onChange={(e) => {
                                e.stopPropagation();
                                props.changeHandler(e);
                            }} name='documentNumber' type='number' autoComplete="new-password" />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Senha</label>
                            <InputPassword value={record.password||""} onChange={(e) => {
                                e.stopPropagation();
                                props.changeHandler(e);
                            }} name="password" autoComplete="off" />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Data da Validade da CNH</label>
                            <Calendar
                                showIcon={true}
                                value={validatedCNH}
                                showTime={false}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    props.changeValidateCNHHandler(e);
                                }}
                                disabled={false}
                                name="dateValidateCNH"
                                dateFormat="dd/mm/yy"
                            />
                        </div>
                    </div>


                </div>
            </div>
        </div>

    );
}
