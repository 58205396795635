import moment from "moment";
import { Reducer } from "../../services/reducer.factory";
import { formatNumber, numberToCurrency } from "../../utils";
import { CREATED, TRAVELING } from "../towerDashboard/constants";
import { NOT_SCRIPTED } from "./contantes";
import { types } from "./scriptwriter.actions";
import { createUniqueID } from "./utils";

const initialState = {
  routesColumns: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
    },
    {
      field: "pointStart.cd",
      header: "CD",
      columnKey: "pointStart.cd",
    },
    {
      field: "vehicle.name",
      header: "Veículo",
      columnKey: "vehicle.name",
    },
    {
      field: "distanceKm",
      header: "Distância",
      columnKey: "distanceKm",
    },
    {
      field: "duration",
      header: "Duração",
      columnKey: "duration",
    },
    {
      field: "routeColor",
      header: "",
      columnKey: "routeColor",
    },
    {
      field: "btnEmbarque",
      header: "",
      columnKey: "btnEmbarque",
    },
    {
      field: "btnRemoveRoute",
      header: "",
      columnKey: "btnRemoveRoute",
    },
  ],
  ordersNotScriptedColumns: [
    {
      field: "vehicleDisponivel",
      header: "",
      columnKey: "vehicleDisponivel",
    },
    {
      field: "code",
      header: "Order",
      columnKey: "code",
    },
    {
      field: "pickup.virtualCD",
      header: "CD",
      columnKey: "pickup.virtualCD",
    },
    {
      field: "pickup.description",
      header: "Origem",
      columnKey: "pickup.description",
    },
    {
      field: "delivery.description",
      header: "Destino",
      columnKey: "delivery.description",
    },
    {
      field: "btnEdit",
      header: "Edite",
      columnKey: "btnEdit",
    },
    {
      field: "btnViewMap",
      header: "Ver",
      columnKey: "btnViewMap",
    },
    {
      field: "btnRemoveOrder",
      header: "",
      columnKey: "btnRemoveOrder",
    },
  ],
  currentRecord: {},
  filterMap: {
    travelStatus: [TRAVELING, CREATED],
    statusTime: null,
  },

  defaultPosition: [-13.625, -50.713],

  lineStyle: {
    weight: 10,
    opacity: 0.8,
  },
  unidadeRecords: [],
  records: [],
  expandedRows: [],
  selectedRoutes: [],
  initialDate: moment().startOf("month").toDate(),
  finalDate: moment().toDate(),
  orders: [],
  ordersNotScriptedSeletected: [],
  ordersNotScripted: [],
  filterParams: [],
  mostrarTodas: true,
  users: [], //lista de usuarios
  usersSelected: [],
  currentUser: null,
  columnsShipment: [
    {
      field: "code",
      header: "Numero",
      columnKey: "code",
    },
    {
      field: "pickup.virtualCD",
      header: "CD",
      columnKey: "pickup.virtualCD",
    },
    {
      field: "pickup.description",
      header: "Origem",
      columnKey: "pickup.description",
    },
    {
      field: "delivery.description",
      header: "Destino",
      columnKey: "delivery.description",
    },
    {
      field: "distance",
      header: "Km",
      columnKey: "distance",
    },

    {
      field: "btnViewMap",
      header: "Ver",
      columnKey: "btnViewMap",
    },
    {
      field: "btnRemoveRoute",
      header: "",
      columnKey: "btnRemoveRoute",
    },
  ],
  columnsStep: [
    {
      field: "type",
      header: "Tipo",
      columnKey: "type",
    },
    {
      field: "code",
      header: "Numero",
      columnKey: "code",
    },
    {
      field: "description",
      header: "Local",
      columnKey: "description",
    },
    {
      field: "btnViewMap",
      header: "",
      columnKey: "btnViewMap",
    },

  ],

  showModalInclusaoOrders: false,
  selectedRouterIncludDelivery: null,
  deliveryInfoBox: null,
  startLocation: { cd: "", location: [], label: "" }, //origem start viagem
  deliveryCDremove: null, //
  vehicles: [],
  routeEditVehicle: null,
  vehicleSelected: null, //veiculo selecionado para alterar
  vehicleTypeColumns: [
    {
      field: "cd",
      header: "CD",
      columnKey: "cd",
    },
    {
      field: "label",
      header: "Depósito",
      columnKey: "label",
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
    },
    {
      field: "weight",
      header: "Peso Máximo",
      columnKey: "weight",
    },
    {
      field: "pallets",
      header: "Pallets",
      columnKey: "pallets",
    },
    {
      field: "custoKm",
      header: "Custo Km",
      columnKey: "custoKm",
    },
  ],
  selectedOrdersDivideRoute: [], //lista de order para dividir a rota
  orderEdit: null,
  filterCDSelected: [],
  typeView: 0,//modo visualização de coleta
  operationType: "",//tipo da operação, delivery or pickup
  onModalGroupByClientIsVisible: false,//flag para mostrar modal e agrupamento por cliente
  deliveryGroupByClientsColumns: [
    {
      field: "cd",
      header: "CD Origem",
      columnKey: "cd",
    }, {
      field: "delivery",
      header: "Delivery",
      columnKey: "delivery",
    }, {
      field: "pallets",
      header: "Pallets",
      columnKey: "pallets",
    }, {
      field: "weight",
      header: "Peso",
      columnKey: "weight",
    },
    {
      field: "distance",
      header: "KM",
      columnKey: "distance",
    }
  ],
  listClientsDelivery: [],
  tolls: [],
  showCercaVirtual: false,
  vehiclesDriverAvailable: [],
  vehicleChangeDriver: null,
  showEditDriver: false,
  driverSelected: null,
  columnsDriversAvailable: [
    {
      field: "plate",
      header: "Placa",
      columnKey: "plate",
    },
    {
      field: "driver.name",
      header: "Motorista",
      columnKey: "driver.name",
    },
    {
      field: "vehicleType.label",
      header: "Tipo Veiculo",
      columnKey: "vehicleType.label",
    },
    {
      field: "driver.documentNumber",
      header: "Documento",
      columnKey: "driver.documentNumber",
    },
    {
      field: "routeId",
      header: "Esta na Rota",
      columnKey: "routeId",
    },
    {
      field: "routeColor",
      header: "Cor",
      columnKey: "routeColor",
    },
    {
      field: "available",
      header: "Em uso rota",
      columnKey: "available",
    },

  ],
};

const reducer = new Reducer(initialState, types);

export function CRUDScriptwriterReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_RECORDS:
      return setRecords(state, action);
    case types.SET_ALL_BUSINESUNITS:
      return setAllBusinessunits(state, action);
    case types.SET_EXPANDED_ROW:
      return setExpandedRows(state, action);
    case types.SET_SELECTED_ROUTES:
      return setSelectedRoutes(state, action);
    case types.SET_INITIAL_DATE_FILTER:
      return setInitialDateFilter(state, action);
    case types.SET_FINAL_DATE_FILTER:
      return setFinalDateFilter(state, action);
    case types.SET_ORDERS_RECORDS:
      return setOrdersRecords(state, action);
    case types.SET_ROWS_ORDERS_SELECTED_NOT_SCRIPTED:
      return setOrdersNotScriptedSeletected(state, action);
    case types.SET_FILTERS:
      return setFilters(state, action);
    case types.CHANGE_MOSTRAR_TODAS:
      return changeMostrarTodas(state, action);
    case types.SET_USERS_SELECTED:
      return setUsersSelected(state, action);
    case types.SET_CURRENT_USER:
      return setCurrentUser(state, action);
    case types.SHOW_SELECTE_ROUTE_ADD_ORDER:
      return showRoutesSelectAddOrders(state, action);
    case types.SET_SELECTE_ROUTE_ADD_ORDER:
      return setSelectedRouterIncludDelivery(state, action);
    case types.SHOW_BOX_INFO_SELECTED_DELIVERY_ON_MAP:
      return showBoxInfoDeliveryMap(state, action);
    case types.SET_SELECTED_VIRTUAL_CD:
      return setSelectecVirtualCD(state, action);
    case types.SET_DELIVERY_CD_REMOVE:
      return setDeliveryToRemoveChangeCD(state, action);
    case types.SET_VEHICLES_RECORDS:
      return setVehicles(state, action);
    case types.SET_ROUTE_EDIT_VEHICLE:
      return setRouteChangeVehicle(state, action);
    case types.SET_VEHICLE_TO_CHANGE:
      return setVehicleToChange(state, action);
    case types.SET_SELECTED_ORDER_DEVIDE_ROUTE:
      return setSelectedOrdersDivideRoute(state, action);
    case types.SET_SELECTED_ORDER_EDIT:
      return showEditDelivery(state, action);
    case types.SET_VISIBLE_CREATE_MANUAL_ROUTE:
      return setVisibleCreateManualRoute(state, action);
    case types.SET_SELECTED_CD_FILTER_IN_ROUTE:
      return onSetSelectedCdFilter(state, action);
    case types.SET_TYPE_VIEW:
      return setTypeView(state, action);
    case types.SET_CREATE_EMBARQUE_ROUTE:
      return setCreateEmbarqueRoute(state, action);
    case types.SET_OPEN_MODAL_GROUP_BY_CLIENTS:
      return setOpenModalGrupoByClients(state, action);
    case types.SET_SELECTED_VEHICLE_GROUP_BY_CLIENTS:
      return setTypeSelectedVeicleGroupClient(state, action);
    case types.SET_SELECTED_CD_GROUP_BY_CLIENTS:
      return setTypeSelectedCDGroupClient(state, action);
    case types.ON_ENABLE_VEHICLE:
      return onEnableVehicle(state, action);
    case types.ON_ADD_PONTO_APOIO:
      return addPontoApoio(state, action);
    case types.UPDATE_POSITION_PONTO_APOIO:
      return updatePostionPontoApoio(state, action);
    case types.SET_TOLLS:
      return setAllTolls(state, action);
    case types.CHANGE_MOSTRAR_CERCA_VIRTUAL:
      return changeMostrarCercaVirtual(state, action);
    case types.SET_VEHICLES_DRIVER_AVAILABLE:
      return setVehiclesDriverAvailable(state, action);
    case types.ON_CHANGE_DRIVE_FROM_ROUTE:
      return onChangeDriveFromRoute(state, action);
    case types.SET_CHANGE_ROUTE_VEHICLE_PLATE:
      return setChangeVehicleAvailableInRoute(state, action);

    default:
      return result;
  }
}

function setChangeVehicleAvailableInRoute(state, action) {
  const vehiclePlate = action.vehiclePlate;//novo veiculo atrelado a rota
  const routeId = action.routeId;
  const vehicleChangeDriver = null;
  const vehiclesDriverAvailable = state.vehiclesDriverAvailable || [];
  if (vehiclesDriverAvailable.length > 0) {
    //remover o veiculo antigo atrelado a rota
    let  routeColor = null;

    for (let index = 0; index < vehiclesDriverAvailable.length; index++) {
      if (vehiclesDriverAvailable[index].routeId == routeId) {
        vehiclesDriverAvailable[index].routeId = null;
        routeColor = vehiclesDriverAvailable[index].routeColor;
        vehiclesDriverAvailable[index].routeColor = null;
        vehiclesDriverAvailable[index].available = true;
        break
      }
    }
    //incluir o novo veiculo atrelado a rota
    for (let index = 0; index < vehiclesDriverAvailable.length; index++) {
      if (vehiclesDriverAvailable[index].plate == vehiclePlate.plate) {
        vehiclesDriverAvailable[index].routeId = routeId;
        vehiclesDriverAvailable[index].routeColor = routeColor;
        vehiclesDriverAvailable[index].available = true;
        break
      }
    }
  }
  
  const records = state.records || [];
  if (records.length > 0) {
    for (let index = 0; index < records.length; index++) {
      if (records[index].oid == routeId) {
        if (records[index].vehicle  ) { 
          //somente uma placa por rota
          records[index].vehicle.vehicleAvailable = vehiclePlate;
          break;
        }
      }
    }
  }
  return { ...state,records, vehicleChangeDriver, showEditDriver: false, vehiclesDriverAvailable };
}

function onChangeDriveFromRoute(state, action) {
  if (action.route) {

    const vehicleChangeDriver = {
      vehicle: action.route.vehicle,
      routeId: action.route.oid,
      routeColor: action.route.routeColor,
    };
    const vehiclesDriverAvailable = state.vehiclesDriverAvailable || [];
    if (state.records && state.records.length > 0) {
      for (let index = 0; index < state.records.length; index++) {
        const route = state.records[index];
        if (route.vehicle && route.vehicle.vehicleAvailable) {
          //somente uma placa por rota
          for (let k = 0; k < vehiclesDriverAvailable.length; k++) {
            if (vehiclesDriverAvailable[k].plate == route.vehicle.vehicleAvailable.plate) {
              vehiclesDriverAvailable[k].routeId = route.oid;
              vehiclesDriverAvailable[k].routeColor = route.routeColor;
              vehiclesDriverAvailable[k].available = false;
            }
          }
        }
      }
    }

    return { ...state, vehicleChangeDriver, showEditDriver: true, vehiclesDriverAvailable };
  } else {
    return { ...state, vehicleChangeDriver: null, showEditDriver: false };
  }
}

function setVehiclesDriverAvailable(state, action) {

  try {
    const vehiclesDriverAvailable = action.vehiclesDriverAvailable || [];

    //incluir veiculos disponiveis dentro da lista de vehicles
    if (vehiclesDriverAvailable.length > 0) {
      //verificar em todas as rotas quais os veiculos/placas ques esta atribuido a rota
      if (state.records && state.records.length > 0) {
        for (let index = 0; index < state.records.length; index++) {
          const route = state.records[index];
          if (route.vehicle && route.vehicle.vehicleAvailable) {
            //somente uma placa por rota
            for (let k = 0; k < vehiclesDriverAvailable.length; k++) {
              if (vehiclesDriverAvailable[k].plate == route.vehicle.vehicleAvailable.plate) {
                vehiclesDriverAvailable[k].routeId = route.oid;
                vehiclesDriverAvailable[k].routeColor = route.routeColor;
                vehiclesDriverAvailable[k].available = false;
              }
            }
          }
        }
      }
      return { ...state, vehiclesDriverAvailable };
    }
    return state;
  } catch (error) {
    console.log(error)
  }
}

function changeMostrarCercaVirtual(state, action) {
  try {
    const showCercaVirtual = action.showCercaVirtual;
    return { ...state, showCercaVirtual };

  } catch (error) {
    console.log(error)
  }

  return state;
}

function setAllTolls(state, action) {
  try {
    const tolls = action.tolls || [];
    return { ...state, tolls };

  } catch (error) {
    console.log(error)
  }

  return state;
}
function updatePostionPontoApoio(state, action) {

  let ordersNotScripted = state.ordersNotScripted || [];
  let orders = state.orders;
  let postionPontApoio = action.postionPontApoio;
  //INCLUIR UM PONTO DE APOIO
  const location = [
    postionPontApoio.lng,
    postionPontApoio.lat
  ]

  if (orders && orders.length > 0) {

    for (let index = 0; index < orders.length; index++) {
      if (orders[index].code == postionPontApoio.code) {
        orders[index].pickup.location = location;
        orders[index].delivery.location = location;
        break
      }
    }
    for (let index = 0; index < ordersNotScripted.length; index++) {
      if (ordersNotScripted[index].code == postionPontApoio.code) {
        ordersNotScripted[index].pickup.location = location;
        ordersNotScripted[index].delivery.location = location;
        break
      }
    }


    state = Object.assign({}, state, { orders, ordersNotScripted });
  }

  return state;
}
function addPontoApoio(state, action) {

  let ordersNotScripted = state.ordersNotScripted || [];
  let orders = state.orders;
  //INCLUIR UM PONTO DE APOIO
  if (orders && orders.length > 0) {

    const listOrderNotScript = [];
    for (const o of orders) {
      if (state.usersSelected.filter((u) => String(u.user).toUpperCase() == String(o.owner.user).toUpperCase()).length > 0) {
        listOrderNotScript.push(o);
        break
      }
    }
    if (orders.length > 0 && listOrderNotScript.length > 0) {
      const id = createUniqueID()


      const owner = listOrderNotScript[0].owner
      const pontoApoio = {
        "amount": [0, 0]
        , status: "NOT_SCRIPTED",
        "pickup": {
          "service": 300,
          "description": `APOIO`,
          "virtualCD": "",
          "location": [
            -56.15633189678193,
            -15.702063103856107
          ]
        },
        "delivery": {
          "service": 300,
          "description": `APOIO`,
          "virtualCD": "",
          "location": [
            -56.15633189678193,
            -15.702063103856107
          ]
        },
        "code": String(id),
        dimensions: [],
        type: "pontoApoio",
        "weight": 0,
        "value": 0,
        "oid": Number(id),
        owner
      }
      orders.push(pontoApoio)
      ordersNotScripted.push(pontoApoio)
      state = Object.assign({}, state, { orders, ordersNotScripted });
    }

  }
  return state;
}


function onEnableVehicle(state, action) {
  try {
    const { enable, indexG, index } = action.params

    let listClientsDelivery = state.listClientsDelivery;
    listClientsDelivery[indexG].deliveryByVehicleType[index].enabled = enable;


    return { ...state, listClientsDelivery };

  } catch (error) {
    console.log(error)
  }

  return state;
}
function setTypeSelectedCDGroupClient(state, action) {
  try {
    const { index, cd } = action.params

    let listClientsDelivery = state.listClientsDelivery;

    listClientsDelivery[index].cdStart = cd
    return { ...state, listClientsDelivery };

  } catch (error) {
    console.log(error)
  }

  return state;
}

function divideDeliveryByVeiculos(lista, indexG) {
  lista[indexG].deliveryByVehicleType = [];
  lista[indexG].totalNotInclude = 0;
  if (lista[indexG].vehicles && lista[indexG].vehicles.length > 0) {

    let deliveryByVehicleType = [];
    let deliveries = [...lista[indexG].deliveries]
    let somaCustoMedioSaco = 0;
    let somaCustoFreteTotal = 0;

    let aindaValido = true;
    let indexVehicle = 0
    //filtrar somente os veiculos com qtde maior que 0
    let vehicles = lista[indexG].vehicles.filter(v => v.qtd > 0) //[...lista[indexG].vehicles]
    let totalPallets = 0;
    if (vehicles.length == 0) {
      return lista
    }

    //remover delivery que a quantidade de pallets é maior q a capacidade do maior veiculo
    deliveries = deliveries.filter(d => d.pallets < vehicles[indexVehicle].pallets)

    deliveries.sort((a, b) => b.pallets - a.pallets)

    let deliveryMaiorQueMaiorVehicle = lista[indexG].deliveries.length - deliveries.length;

    const getTotalPallets = (deliveriesList) => {
      totalPallets = 0;
      for (let index = 0; index < deliveriesList.length; index++) {
        totalPallets += deliveriesList[index].pallets;
      }
      return totalPallets;
    }
    const getNextVehicle = () => {
      let currentVehicle = vehicles[indexVehicle]
      for (let index = indexVehicle + 1; index < vehicles.length; index++) {
        if (totalPallets <= currentVehicle.pallets && currentVehicle.pallets <= vehicles[index].pallets) {
          break
        }
        if (totalPallets <= vehicles[index].pallets) {
          currentVehicle = vehicles[index];
          indexVehicle = index;
        }

      }
      return currentVehicle
    }

    while (aindaValido) {
      totalPallets = getTotalPallets(deliveries, totalPallets);
      if (totalPallets == 0) {
        aindaValido = false;
        break
      }
      //montar os veiculos o maximo de 
      while (deliveries.length > 0 && aindaValido) {
        if (indexVehicle >= vehicles.length) {
          aindaValido = false;
          break
        }
        totalPallets = getTotalPallets(deliveries);
        let currentVehicle = vehicles[indexVehicle]

        let totalVeic = deliveryByVehicleType.filter(dv => dv.vehicle.code == currentVehicle.code)

        //verificar se a quantidade de tipo de veiculo ja chegou na quantidade informada
        if (currentVehicle && currentVehicle.qtd <= totalVeic.length) {
          indexVehicle += 1
        }

        if (currentVehicle && (totalPallets < currentVehicle.pallets || currentVehicle.qtd <= totalVeic.length)) {
          currentVehicle = getNextVehicle()
        }

        if (!currentVehicle) {
          aindaValido = false;
          break
        }




        // let nextVehicle = vehicles.length + 1 > indexVehicle ? vehicles[indexVehicle + 1] : null; 
        // //quando o total restante for menor que a quantidade de pallets do proximo veiculo(menor), passar a usar esse veiculo
        // if (nextVehicle && totalPallets < nextVehicle.pallets) {
        //   currentVehicle = nextVehicle;
        //   indexVehicle += 1
        // }
        if (currentVehicle.pallets < 1) {
          console.log("erro, veiculo com sem quantidade de pallets configurados")
          currentVehicle.pallets = 1;
        }
        const percentOcupacao = (totalPallets / currentVehicle.pallets) * 100;
        if (percentOcupacao < 5) {
          //caso o restante de pallets nao ocupa mais de 60% da capacidade do veiculo,
          //deixar essas delivery para ser usada com outras em delivery em outro veiculo
          console.log("indexVehicle=", indexVehicle)
          aindaValido = false;
          break
        }



        let delivVehicle = {
          deliveries: [],
          pallets: 0,
          sacos: 0,
          custoSaco: 0,
          custoFrete: 0,
          valCustoSaco: 0,
          km: 0,
          enabled: true,
          vehicle: { ...currentVehicle }
        }



        //deixar 1 pallet  
        while (deliveries.length > 0 && delivVehicle.pallets < currentVehicle.pallets) {
          //
          let delivery;
          let indexDeliv = 0;
          let encontrouDeliv = false;
          let restanteCompletPallets = currentVehicle.pallets - delivVehicle.pallets;
          for (; indexDeliv < deliveries.length; indexDeliv++) {
            if (deliveries[indexDeliv].pallets <= restanteCompletPallets) {
              encontrouDeliv = true;
              break;
            }
          }
          if (encontrouDeliv) {
            delivery = deliveries.splice(indexDeliv, 1)[0];

            delivVehicle.pallets += delivery.pallets;
            delivVehicle.sacos += delivery.sacos;
            if (delivery.km > delivVehicle.km) {
              delivVehicle.km = delivery.km;
            }
            const custoFrete = delivVehicle.vehicle.custoKm * delivVehicle.km;
            const custoSaco = custoFrete / delivVehicle.sacos
            delivVehicle.custoSaco = numberToCurrency(custoSaco, "R$");
            delivVehicle.valCustoSaco = custoSaco;
            delivVehicle.custoFrete = custoFrete;


            delivVehicle.ocupation = (delivVehicle.pallets / delivVehicle.vehicle.pallets) * 100;
            // delivVehicle.ocupationPeso =  (delivVehicle.weight / delivVehicle.vehicle.weight) * 100;
            delivVehicle.deliveries.push(delivery);
          } else {
            //preencheu um veiculo 
            //verifica se ja acabou os veiculos compativel
            if (indexVehicle == vehicles.length) {
              aindaValido = false;
            }
            //verificar se existe uma ou outra delivery
            let encont = false;
            for (let ind = 0; ind < deliveries.length; ind++) {
              if (deliveries[ind].pallets <= currentVehicle.pallets) {
                encont = true;
                break
              }
            }
            if (!encont)
              indexVehicle += 1
            break
          }
        }

        if (delivVehicle.deliveries.length > 0 && delivVehicle.pallets > 0) {
          somaCustoMedioSaco += delivVehicle.valCustoSaco
          somaCustoFreteTotal += delivVehicle.custoFrete
          deliveryByVehicleType.push(delivVehicle);
        }

      }
      let totalSobraMenorVeiculo = deliveries.length;

      lista[indexG].deliveryByVehicleType = deliveryByVehicleType;
      lista[indexG].deliveryMaiorQueMaiorVehicle = deliveryMaiorQueMaiorVehicle;
      lista[indexG].totalSobraMenorVeiculo = totalSobraMenorVeiculo
      lista[indexG].custoMedioSaco = somaCustoMedioSaco / deliveryByVehicleType.length;
      lista[indexG].somaCustoFreteTotal = somaCustoFreteTotal;
      //quando não completar um veiculo ou pelo menos 50% da capacidade
      //do menor veiculo disponivel
      aindaValido = false;
    }
  }

  return lista;

}
/*
function addVehicles(indexG, listClientsDelivery) {
  if (!listClientsDelivery[indexG].vehicles)
    listClientsDelivery[indexG].vehicles = [];
  
    if (listClientsDelivery[indexG].vehicles.length == 0) {
      if (enable) {
        listClientsDelivery[indexG].vehicles.push(vehicle);
      }
      listClientsDelivery = divideDeliveryByVeiculos(listClientsDelivery, indexG)
      return listClientsDelivery;
    }
  
    let index = 0;
    let encont = false;
    for (; index < listClientsDelivery[indexG].vehicles.length; index++) {
      if (listClientsDelivery[indexG].vehicles[index].code === vehicle.code) {
        encont = true;
        break
      }
    }
    //se esta selecionando
    if (enable) {
      if (!encont) {
        listClientsDelivery[indexG].vehicles.push(vehicle);
      }
    } else {
      if (encont) {
        //remover
        listClientsDelivery[indexG].vehicles.splice(index, 1);
      }
    } 
  let vehicles = listClientsDelivery[indexG].vehicles;

  vehicles.sort((a, b) => {
    return b.pallets - a.pallets;
  });
  listClientsDelivery[indexG].vehicles = vehicles;
  listClientsDelivery = divideDeliveryByVeiculos(listClientsDelivery, indexG)
  return listClientsDelivery
}
*/
function setTypeSelectedVeicleGroupClient(state, action) {
  try {
    const { indexG, qtd, index } = action.params
    let qt = 0;
    try {
      if (String(qtd).trim().length > 0)
        qt = parseInt(String(qtd).trim())
      if (isNaN(qt)) {
        qt = 0;
      }
    } catch (error) {
      console.log(error)
    }
    let listClientsDelivery = state.listClientsDelivery;
    listClientsDelivery[indexG].vehicles[index].qtd = qt;
    listClientsDelivery = divideDeliveryByVeiculos(listClientsDelivery, indexG);// addVehicles(indexG, listClientsDelivery)
    return { ...state, listClientsDelivery };
  } catch (error) {
    console.log(error)
  }

  return state;
}

function setOpenModalGrupoByClients(state, action) {
  return { ...state, onModalGroupByClientIsVisible: action.show };
}

function setCreateEmbarqueRoute(state, action) {
  const oids = action.oids;
  const enable = action.enable;

  let records = state.records;
  for (let index = 0; index < records.length; index++) {
    oids.forEach(oid => {
      if (records[index].oid == oid) {
        records[index].createEmbarque = enable;
      }
    });
  }

  return { ...state, records };
}

function setTypeView(state, action) {
  const typeView = action.typeView;
  return { ...state, typeView };
}

function onSetSelectedCdFilter(state, action) {
  const filterCDSelected = action.filterCDSelected;
  return { ...state, filterCDSelected };
}

function setVisibleCreateManualRoute(state, action) {
  const isCreateManualRoute = action.visible;
  return { ...state, isCreateManualRoute };
}

function showEditDelivery(state, action) {
  const orderEdit = action.orderEdit;
  return { ...state, orderEdit };
}
function setSelectedOrdersDivideRoute(state, action) {
  const selectedOrdersDivideRoute = action.selectedOrdersDivideRoute;
  if (selectedOrdersDivideRoute) {
    for (let index = 0; index < selectedOrdersDivideRoute.length; index++) {
      selectedOrdersDivideRoute[index].selected = true;
    }
  }
  return { ...state, selectedOrdersDivideRoute: [...selectedOrdersDivideRoute], typeView: 0 };
}
function setVehicleToChange(state, action) {
  const vehicleSelected = action.vehicleSelected;
  return { ...state, vehicleSelected };
}

function setRouteChangeVehicle(state, action) {
  const routeEditVehicle = action.routeEditVehicle;
  return { ...state, routeEditVehicle };
}
function setVehicles(state, action) {
  const vehicles = action.records;
  if (!vehicles) {
    vehicles = [];
  }
  let vehicleSelected = state.vehicleSelected;
  if (vehicleSelected && vehicles.length > 0) {
    vehicles.forEach((v) => {
      if (v.oid == vehicleSelected.oid) {
        vehicleSelected = v;
      }
    });
  }

  return { ...state, vehicles, vehicleSelected };
}

function setDeliveryToRemoveChangeCD(state, action) {
  const deliveryCDremove = action.deliveryCDremove;
  return { ...state, deliveryCDremove };
}

function setSelectecVirtualCD(state, action) {
  const startLocation = action.startLocation;
  return { ...state, startLocation };
}

function showBoxInfoDeliveryMap(state, action) {
  const deliveryInfoBox = action.delivery;
  const operationType = action.operationType;
  return { ...state, deliveryInfoBox, operationType };
}

function setSelectedRouterIncludDelivery(state, action) {
  const selectedRouterIncludDelivery = action.route;
  let startLocation = { cd: "", location: [], documentNumber: "", label: "" };
  if (selectedRouterIncludDelivery) {
    startLocation = {
      cd: selectedRouterIncludDelivery.pointStart.cd,
      documentNumber: selectedRouterIncludDelivery.pointStart.documentNumber,
      location: selectedRouterIncludDelivery.pointStart.location,
      key: selectedRouterIncludDelivery.pointStart.cd + "-" + selectedRouterIncludDelivery.pointStart.documentNumber,
      label: selectedRouterIncludDelivery.pointStart.cd + "-" + selectedRouterIncludDelivery.pointStart.documentNumber,
    };
  }
  return { ...state, selectedRouterIncludDelivery, startLocation };
}
function showRoutesSelectAddOrders(state, action) {
  const showModalInclusaoOrders = action.value;
  return { ...state, showModalInclusaoOrders, deliveryInfoBox: null };
}
function setCurrentUser(state, action) {
  const currentUser = action.currentUser;
  return { ...state, currentUser };
}

function setUsersSelected(state, action) {
  let usersSelected = state.usersSelected;
  const users = action.user;
  usersSelected = users;
  return { ...state, usersSelected };
}

function changeMostrarTodas(state, action) {
  const mostrarTodas = action.mostrarTodas;
  return { ...state, mostrarTodas, deliveryInfoBox: null };
}

function setFilters(state, action) {
  let filterParams = state.filterParams;
  filterParams = action.filterParams;
  return { ...state, filterParams };
}
function setOrdersNotScriptedSeletected(state, action) {
  let ordersNotScriptedSeletected = action.selectedOrderns;

  return { ...state, ordersNotScriptedSeletected };
}
function setAllBusinessunits(state, action) {
  let unidadeRecords = state.unidadeRecords;
  unidadeRecords = action.records;
  return { ...state, unidadeRecords };
}
function setRecords(state, action) {
  let records = state.records;
  records = action.records;

  let selectedRoutes = records ? records.filter(r => !r.invalid) : [];
  //filtrar clientes em rotas diferentes
  //descobrir se tem delivery do mesmo cliente em outras rotas
  //caso tiver, alterar a cor da rota para deixar destacada para o usuario saber
  let listClientsDelivery = [];

  const getVehiclesByCd = (cds) => {
    const vehiclesDeli = state.vehicles ? state.vehicles.filter((v) => cds.indexOf(String(v.cd).trim().toUpperCase()) > -1) : []
    const listVehicle = []
    //agora filtrar os duplicados
    for (const veh of vehiclesDeli) {
      if (listVehicle.filter((v) => v.code == veh.code).length == 0) {
        listVehicle.push(veh);
      }
    }

    return listVehicle
  }

  if (selectedRoutes) {



    selectedRoutes.forEach(r => {
      // console.log("rota=", r);
      //somente para rotas ainda nao foi otimizada por cliente
      if (!r.optimizedByClient)
        r.shipments.forEach(s => {
          //verificar se ja foi adicionando na lista esse cliente
          //caso ja, somar os valores
          let encontrou = false;
          let user_client_unique_code = s.user_client_unique_code
          let isDevolucao = s.dv == "dv"
          if (isDevolucao) {
            user_client_unique_code = s.sender.user_sender_unique_code
          }

          for (let index = 0; index < listClientsDelivery.length; index++) {

            if (listClientsDelivery[index].clientDoc == user_client_unique_code) {
              encontrou = true;
              listClientsDelivery[index].pallets += s.amount[0];//soma os pallets
              listClientsDelivery[index].weight += s.amount[1];//soma o peso
              if (listClientsDelivery[index].routeIds.indexOf(r.oid) == -1) {
                listClientsDelivery[index].routeIds.push(r.oid);
              }
              if (listClientsDelivery[index].cds.indexOf(s.user_depot_unique_code) == -1) {
                listClientsDelivery[index].cds.push(s.user_depot_unique_code);
              }
              listClientsDelivery[index].cdStart = { cd: s.user_depot_unique_code, location: s.pickup.location }

              listClientsDelivery[index].deliveries.push({
                delivery: s.code,
                pallets: s.amount[0],
                weight: s.amount[1],
                sacos: s.sacos,
                route: r.oid,
                cd: s.user_depot_unique_code,
                km: s.kmOriginDest,
                distance: formatNumber(s.kmOriginDest, 3)
              });

              // listClientsDelivery[index].vehicles = getVehiclesByCd(listClientsDelivery[index].cds)

              //ordenar por CD
              listClientsDelivery[index].deliveries.sort((a, b) => {
                if (a.cd < b.cd) {
                  return -1;
                }
                if (a.cd > b.cd) {
                  return 1;
                }
                return 0;
              });

              if (!listClientsDelivery[index].vehicles) {
                listClientsDelivery[index].vehicles = []
              }

            }

          }
          if (!encontrou) {

            listClientsDelivery.push({
              clientDoc: user_client_unique_code,
              name: s.client_formal_name,
              pallets: s.amount[0],
              weight: s.amount[1],
              routeIds: [r.oid],
              cdStart: { cd: s.user_depot_unique_code, location: s.pickup.location },
              cds: [s.user_depot_unique_code],
              vehicles: [],
              deliveries: [{
                delivery: s.code,
                pallets: s.amount[0],
                weight: s.amount[1],
                route: r.oid,
                sacos: s.sacos,
                cd: s.user_depot_unique_code,
                km: s.kmOriginDest,
                distance: formatNumber(s.kmOriginDest, 3)
              }],
            });
          }
        })
    })
  }



  for (let i = 0; i < listClientsDelivery.length; i++) {
    let vehicles = getVehiclesByCd(listClientsDelivery[i].cds)
    for (let indexV = 0; indexV < vehicles.length; indexV++) {
      // listClientsDelivery = addVehicles(index, listClientsDelivery, vehicles[indexV], true)
      if (listClientsDelivery[i].vehicles.filter(v => v.code === vehicles[indexV].code).length == 0) {
        listClientsDelivery[i].vehicles.push({
          ...vehicles[indexV],
          qtd: 15
        })
      }
    }

    let vehiclesL = listClientsDelivery[i].vehicles;
    vehiclesL.sort((a, b) => {
      return b.fatorValorPallets - a.fatorValorPallets;
    });


    listClientsDelivery[i].vehicles = vehiclesL
    listClientsDelivery = divideDeliveryByVeiculos(listClientsDelivery, i);
  }

  // console.log("listClientsDelivery=", listClientsDelivery);
  listClientsDelivery = listClientsDelivery.filter(l => l.routeIds.length > 1)


  return { ...state, records, selectedRoutes, deliveryInfoBox: null, listClientsDelivery };
}

function setExpandedRows(state, action) {
  state = Object.assign({}, state, { expandedRows: action.expandedRows });
  return state;
}
function setSelectedRoutes(state, action) {
  state = Object.assign({}, state, { selectedRoutes: action.selectedRoutes });
  return state;
}
function setInitialDateFilter(state, action) {
  let initialDate = state.initialDate;
  initialDate = action.initialDate;
  return { ...state, initialDate };
}

function setFinalDateFilter(state, action) {
  let finalDate = state.finalDate;
  finalDate = action.finalDate;
  return { ...state, finalDate };
}

function setOrdersRecords(state, action) {
  let orders = state.orders;
  orders = action.orders;


  let ordersNotScripted = [];

  //extrair usuarios das orders
  let users = [];
  if (orders) {
    orders.forEach((order) => {
      const { owner } = order;
      const userName = String(owner.user).toUpperCase();
      let encontrou = false;
      for (let index = 0; index < users.length; index++) {
        if (users[index].user === userName) {
          encontrou = true;
          break;
        }
      }
      if (!encontrou) {
        users.push({
          user: userName,
        });
      }

      //separar as ordens com status NOT_SCREPTED
      if (order.status == NOT_SCRIPTED) {
        ordersNotScripted.push(order);
      }
    });
  }

  let usersSelected = [];
  let currentUser = state.currentUser;
  if (currentUser) {
    for (let index = 0; index < users.length; index++) {
      currentUser = String(currentUser).toUpperCase();
      if (String(users[index].user).toUpperCase() === String(currentUser).toUpperCase()) {
        usersSelected = [users[index]];
      }
    }
  }


  ordersNotScripted = ordersNotScripted.sort((a, b) => {
    if (a.pickup.virtualCD < b.pickup.virtualCD) {
      return -1;
    }
    if (a.pickup.virtualCD > b.pickup.virtualCD) {
      return 1;
    }
    return 0;
  });


  return { ...state, orders, users, usersSelected, ordersNotScripted, deliveryInfoBox: null };
}
