import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { actions } from '../cerca-virtual.actions';
import { Page } from "../../../components/page/Page";
import { Box, Container } from '@material-ui/core';
import "./CercaVirtualMap.scss";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Toolbar from '../../../components/toolbar/Toolbar';
import { AppService, BUSINESS_UNIT_CHANGE_EVENT_NAME, COMPANY_CHANGE_EVENT_NAME } from '../../../services/app.service';

const mapStateToProps = state => {
  const selectedRecords = state.crudCercaVirtualState.selectedRecords;

  const records = state.crudCercaVirtualState.records || [];
  const selectedRecordEdit = state.crudCercaVirtualState.selectedRecordEdit;

  let btnSaveIsDisabled = true;
  if (records) {
    btnSaveIsDisabled = records.length === 0;
  }
  return {
    columns: state.crudCercaVirtualState.columns,
    records,
    selectedRecords,
    inputCercaName: state.crudCercaVirtualState.inputCercaName,
    btnSaveIsDisabled,
    selectedRecordEdit,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.loadAll()),
    onSelectionChange: (value) => {
      dispatch(actions.setPolygonsSelectedRecords(value))
    },
    inputCercaNameChange: (e) => {
      dispatch(actions.inputCercaNameChange(e.target.value))
    },
    addNewCerca: () => {
      dispatch(actions.addNewCerca())
    },
    saveHandler: () => {
      dispatch(actions.saveCercaVirtual())
    },

    removerCercaVirtual: (cerca) => {
      dispatch(actions.removeCercaVirtual(cerca))
    },
    onSelectionToEdit: (cerca) => {
      dispatch(actions.selectedRecordEdit(cerca))
    },
    onUpdateRecordEdit: () => {
      dispatch(actions.setUpdateRecordEdit())
    }, 
  }
}
var polygons = [];
class CercaVirtualFormPageContainer extends Component {


  constructor(props) {
    super(props);
    this.state = {
      drawing: false,
      polygonCoordinates: [],
      currentPolygon: null,
      currentPolyline: null
    };

  }

  componentDidMount() {
    this.map = L.map('mapCercaVirtual').setView([-13.625, -50.713], 5);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(this.map);

    this.map.on('mousedown', this.addPoint);
    this.map.on('mousemove', this.drawPolyline);

    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.onSelectionChange([]);
    this.removePolygon();
    this.props.load();
  }


  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.onSelectionChange([]);
      this.removePolygon();
      this.props.load();
    }
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
    this.props.onSelectionChange([]);
    this.removePolygon();
  }

  handleKeyDown = (e) => {
    if (this.props.selectedRecordEdit) {
      if (e.key === 'Control') {
        this.setState({ drawing: true });
      }
    }
  }

  handleKeyUp = (e) => {
    if (this.props.selectedRecordEdit) {
      if (e.key === 'Control') {
        this.finishDrawing();
        this.setState({ drawing: false });
      }
    }
  }

  addPoint = (e) => {
    // if (this.props.selectedRecords) {
    if (!this.state.drawing) return; // Verifica se a tecla Ctrl está pressionada
    const newCoordinates = [...this.state.polygonCoordinates, e.latlng];
    this.setState({
      polygonCoordinates: newCoordinates
    });
    if (this.state.currentPolyline) {
      this.state.currentPolyline.setLatLngs(newCoordinates);
    } else {
      this.setState({
        currentPolyline: this.map ? L.polyline(newCoordinates, { color: '#74007a', dashArray: '18, 10', customId: "uniquePolygonId" }).addTo(this.map) : null
      });
    }
    // }
  }

  drawPolyline = (e) => {
    // if (this.props.selectedRecords) {
    if (!this.state.drawing || !this.state.currentPolyline) return;
    const newCoordinates = [...this.state.polygonCoordinates, e.latlng];
    this.state.currentPolyline.setLatLngs(newCoordinates);
    // }
  }
  // Função para encontrar e remover o polígono pelo customId

  finishDrawing = () => {
    // if (this.props.selectedRecords) {
    if (!this.state.drawing || !this.state.currentPolyline) return;
    const newCoordinates = this.state.polygonCoordinates;
    if (this.state.currentPolygon) {
      this.state.currentPolygon.setLatLngs(newCoordinates);
    } else {
      this.setState({
        currentPolygon: this.map ? L.polygon(newCoordinates, { color: '#caa7ff', customId: "uniquePolygonId" }).addTo(this.map) : null
      });
    }
    this.removePolygon();
    this.props.selectedRecordEdit.polygonCoordinates = newCoordinates;
    this.props.onUpdateRecordEdit();

    this.state.currentPolyline.remove();
    this.setState({
      polygonCoordinates: [],
      currentPolyline: null
    });

    // }
  }

  removePolygon = () => {
    try {
      if (this.state.currentPolygon) {
        this.state.currentPolygon.remove();
        this.setState({
          currentPolygon: null
        });
      }
    } catch (error) {
      console.log(error)
    }
    try {
      polygons.forEach(polygon => {
        polygon.remove();
      });
      polygons = [];

    } catch (error) {
      console.log(error)
    }
  }

  rendererizaCercaVirtual = () => {
    if (this.state.drawing) return;
    //remover cerca virtual anterior
    //  this.removePolygon();
    this.removePolygon();

    if (!this.map || !this.props.selectedRecords) return;
    try {

      if (Array.isArray(this.props.selectedRecords)) {
        if (this.props.selectedRecords.length === 0) {
          return;
        };
        this.props.selectedRecords.forEach(record => {
          if (record.polygonCoordinates && record.polygonCoordinates.length > 0) {
            const polygon = L.polygon(record.polygonCoordinates, { color: 'blue', customId: "uniquePolygonId" }).addTo(this.map);
            polygon.bindPopup(L.popup().setContent(record.name));
            polygons.push(polygon); 
          }
        });
      } else {
        // if (this.props.selectedRecords.polygonCoordinates && this.props.selectedRecords.polygonCoordinates.length > 0) {
        //   const polygon = L.polygon(this.props.selectedRecords.polygonCoordinates, { color: 'blue',customId: "uniquePolygonId" }).addTo(this.map);
        //   // console.log("polygon:",polygon.options.customId);
        //   polygon.bindPopup(L.popup().setContent(this.props.selectedRecords.name));
        //   // this.this.props.polygons.push(polygon);
        //   this.props.addPolygons(polygon);
        // }
      }
    } catch (error) {
      console.error('Erro ao renderizar cerca virtual:', error);
    }
  }

  render() {
    const title = `Cerca Virtual`;
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} btnSaveIsDisabled={this.props.btnSaveIsDisabled} saveButtonStyle='highlighted'>
          </Toolbar>
        </div>
      </div>
    );

    const btnRemoverTemplate = (rowData, column) => {
      return (
        <div className='content-btn-cerca-virtual'>
          <button
            className={"lognex-btn-highlighted-inverse"}
            style={{
              width: "30px",
              cursor: "pointer",
              height: "30px",
              margin: "auto",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.removerCercaVirtual(rowData)
              // setTimeout(() => { this.removePolygon(); this.rendererizaCercaVirtual(); }, 2000);
            }}
          >
            <div className="">
              <i className="pi pi-trash"></i>
            </div>
          </button>
        </div>
      );
    }
    const btnEdtingTemplate = (rowData, column) => {
      return (
        <div className='content-btn-cerca-virtual'>
          <button
            className={"lognex-btn-highlighted-inverse"}
            style={{
              cursor: "pointer",
              margin: "auto",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.removePolygon();
              this.props.onSelectionToEdit(rowData)
            }}
          >
            <div className="">
              <i className="pi pi-pencil"></i>
            </div>
          </button>
        </div>
      );
    }
    const defaultTemplate = (rowData, column) => {
      const valConteudo = rowData[column.field]
      if (this.props.selectedRecordEdit && rowData.name == this.props.selectedRecordEdit.name) {
        return (
          <div style={{ color: "red", fontSize: 16 }}>
            {valConteudo}
          </div>
        );
      }
      return (
        <span >
          {valConteudo}
        </span>
      );
    }

    const edtingTemplate = (rowData, column) => {

      if (this.props.selectedRecordEdit && rowData.name == this.props.selectedRecordEdit.name) {
        return (
          <div style={{ color: "red", fontSize: 16 }}>
            <i className="pi pi-pencil" style={{ color: "red" }}></i>
          </div>
        );
      }
      return (
        <div>
        </div>
      );
    }


    let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />]
    columns = [...columns,
    ...this.props.columns.map(col => {
      if (col.field === 'oid') {
        return (<Column {...col} key={col.field} style={{ width: '5em' }} body={defaultTemplate} />);
      }
      if (col.field === 'name') {
        return (<Column {...col} key={col.field} body={defaultTemplate} />);
      }
      if (col.field === 'remove') {
        return (<Column {...col} key={col.field} style={{ width: "60px" }} body={btnRemoverTemplate} />);
      }
      if (col.field === 'edit') {
        return (<Column {...col} key={col.field} style={{ width: "60px" }} body={btnEdtingTemplate} />);
      }

      if (col.field === 'editando') {
        return (<Column {...col} key={col.field} style={{ width: "48px" }} body={edtingTemplate} />);
      }


      return (<Column {...col} key={col.field} />)
    })]


    const content = (
      <Box className='cerca-virtual'>
        <Box id="mapCercaVirtual" />
        <Box className='content-lateral-dir'>
          <Container>

            <Box m={2}>
              <h1>Cerca Virtual</h1>
              <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
                <InputText
                  type="text"
                  placeholder="Nome da Cerca Virtual"
                  value={this.props.inputCercaName}
                  onChange={this.props.inputCercaNameChange}
                />

                <button
                  className={"lognex-btn-highlighted-inverse"}
                  style={{
                    width: "60px",
                    cursor: "pointer",
                    height: "32px",
                    marginLeft: "5px",
                  }}
                  disabled={!this.props.inputCercaName || this.props.inputCercaName.length === 0}
                  onClick={this.props.addNewCerca}
                >
                  <div className="">
                    <i className="pi pi-plus"></i>
                  </div>

                </button>
              </Box>

              <DataTable
                value={this.props.records}
                emptyMessage="Não foram encontrados registros"
                onRowClick={(e) => {
                  // e.originalEvent.preventDefault();
                  // e.originalEvent.stopPropagation();
                  // this.removePolygon();
                  // this.props.onSelectionChange([e.data]);
                }}
                selectionMode="multiple"
                selection={this.props.selectedRecords}
                metaKeySelection={false}
                onSelectionChange={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  this.removePolygon();
                  this.props.onSelectionChange(e.value);
                }}
              >
                {columns}

              </DataTable>


            </Box>
          </Container>
        </Box>
      </Box>
    );

    this.rendererizaCercaVirtual();
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CercaVirtualFormPageContainer);