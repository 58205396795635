import React from 'react';
import { DataTable } from 'primereact/datatable';

export const OccurrenceTypeForAppList = (props) => (
    <DataTable
        selectionMode="single"
        onFilter={props.onFilter}
        onSort={props.onSort}
        onPage={props.onPage}
        value={props.records}
        lazy={true}
        totalRecords={props.totalRecords}
        sortOrder={props.order}
        sortField={props.sortField}
        paginator={true}
        first={props.first}
        rows={10}   // exibir apenas 10 registro por vez
        emptyMessage="Não foram encontrados registros"
    // rowsPerPageOptions={[5, 10, 20, 50]}
    >
        {props.columns}

    </DataTable>
)