import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Dropdown } from "primereact/dropdown";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { OccurrenceTypeList } from "./OccurrenceTypeList";
import { actions } from '../occurrenceType.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'

const mapStateToProps = state => {
    // console.log("console mapStateToProps - OcorrenceType",state.crudOccurrenceTypeState.records)
    const currentMenu = state.menuState.currentRecord;
    const userConfigurations = state.appState.userConfigurations;
    let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
    return {
        columns: state.crudOccurrenceTypeState.columns,
        records: state.crudOccurrenceTypeState.records,
        first: state.crudOccurrenceTypeState.index,
        rows: state.crudOccurrenceTypeState.max,
        totalRecords: state.crudOccurrenceTypeState.count,
        sortField: state.crudOccurrenceTypeState.sortField,
        order: state.crudOccurrenceTypeState.sortOrder,
        booleanFilter: state.crudOccurrenceTypeState.booleanFilter,
        booleanTypes: [
            { label: 'Todos', value: '' },
            { label: 'Sistema', value: "SYSTEM" },
            { label: 'Empresa', value: 'COMPANY' }
        ],
        menuItems: state.menuState.items,
        currentMenu,
        isFavorite,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
        sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
        applyFilter: filterParams => {
            dispatch(actions.setType(null));
            dispatch(actions.applyFilter(filterParams))
        },
        changeBooleanFilter: (e) => {
            const target = e.target;

            dispatch(actions.setBooleanFilter(target.name, target.value));
            let filters = [];
            if (target.value || target.value === false) {
                filters = [{
                    campo: `${target.name}`,
                    valor: target.value,
                    tipoFiltro: 'IGUAL_A'
                }];
            }
            dispatch(actions.applyFilter(filters));

        },
        load: () => dispatch(actions.load()),
        userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
        setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
        toggleFavorite: (isFavorite, oidMenu) => {
            dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
        }
    }
}

class OccurrenceTypeListPageContainer extends React.Component {

    newHandler = () => {
        this.props.history.push(`${routes.PATH_OCCURRENCETYPE}/novo`)
    }

    componentWillReceiveProps(newProps) {
        if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
            const menuPath = this.props.history.location.pathname;
            this.props.setCurrentMenu(menuPath)
        }

        if ((!this.props.currentMenu && newProps.currentMenu) ||
            (this.props.currentMenu !== newProps.currentMenu)) {
            const oidMenu = newProps.currentMenu.oid;
            this.props.userLastAccess(oidMenu)
        }
    }

    componentDidMount() {
        this.props.load();
        AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
        AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
        const menuPath = this.props.history.location.pathname;
        this.props.setCurrentMenu(menuPath)
    }
    componentWillUnmount() {
        AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
        AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    }

    onReceiveAppNotifications = (eventName, params) => {
        if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
            this.props.load();
        }
    }

    onPage = (e) => {
        this.props.paginate({
            max: e.rows,
            index: e.first
        })
    }
    onSort = (e) => {
        // console.log('sorting', e);
        this.props.sortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })


    }
    onFilter = (e) => {
        // console.log(e);
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            if (e.filters.oid) {
                filters.push({
                    campo: key,
                    valor: parseInt(e.filters[key].value),
                    tipoFiltro: 'IGUAL_A'
                })
            } else {
                filters.push({
                    campo: key,
                    valor: e.filters[key].value,
                    tipoFiltro: 'CONTEM'
                })
            }
        })
        // console.log(filters);
        this.props.applyFilter(filters)
    }

    onFavorite = (e) => {
        if (this.props.currentMenu) {
            const oidMenu = this.props.currentMenu.oid
            const isFavorite = this.props.isFavorite;
            this.props.toggleFavorite(isFavorite, oidMenu)
        }
    }
    render() {
        const isFavorite = this.props.isFavorite;

        const header = (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1>Tipos de ocorrências</h1>

                    <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
                        title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
                </div>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
                    </Toolbar >
                </div>
            </div>
        );

        const oidTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: "center" }} className="id-link"><Link to={`${routes.PATH_OCCURRENCETYPE}/${rowData.oid}`}>{rowData.oid}</Link></div>
            )
        }

        const codeTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: "center" }} >{rowData.code}</div>
            )
        }


        const groupTypeTemplate = (rowData, column) => {
            if (rowData.groupType === "COMPANY") {
                return (
                    <div>{"Empresa"}</div>
                )
            }
            return (
                <div style={{ textAlign: "center" }}>{"Sistema"}</div>
            )
        }

        const booleanFilter = (field) => {
            return (<Dropdown style={{ "width": '90%' }} name={field}
                value={this.props.booleanFilter[field]} options={this.props.booleanTypes} onChange={(e) => this.props.changeBooleanFilter(e)} />)
        }

        const columns = this.props.columns.map(col => {
            if (col.field === 'oid') {
                return (<Column {...col} key={col.field} body={oidTemplate} style={{ width: '10%' }} />);
            } else if (col.field === 'groupType') {
                return (<Column {...col} key={col.field} body={groupTypeTemplate} filterElement={booleanFilter(col.field)} style={{ width: '10%' }} />);
            } else if (col.field === 'code') {
                return (<Column {...col} key={col.field} body={codeTemplate} style={{ width: '20%' }} />);
            }

            return (<Column {...col} key={col.field} />)
        })

        const content = <OccurrenceTypeList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />;
        return <Page header={header} content={content} />;

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OccurrenceTypeListPageContainer);
