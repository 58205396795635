import { types } from "./cerca-virtual.actions";
import { Reducer } from "../../services/reducer.factory";

 
const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: false
    },
    {
      field: "editando",
      header: "Edit",
      columnKey: "editando",
      sortable: false,
      filter: false
    },
    
    {
      field: "edit",
      header: "",
      columnKey: "edit",
      sortable: false,
      filter: false
    },
    {
      field: "remove",
      header: "",
      columnKey: "remove",
      sortable: false,
      filter: false
    },
  ],
  inputCercaName: '',
  selectedRecords: null,
  selectedRecordEdit: null, 
};

export function CRUDCercaVirtualReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_INPUT_CERCA_NAME:
      return inputCercaNameChange(state, action);
    case types.ADD_NEW_CERCA:
      return addNewCerca(state, action);
    case types.SET_SELECTED_RECORDS:
      return setPolygonsSelectedRecords(state, action);
    case types.SET_RECORD_TO_EDIT:
      return selectedRecordEdit(state, action);
    case types.UPDATE_RECORD_IN_EDIT:
      return setUpdateRecordEdit(state, action);
    case types.REMOVE_RECORD_FROM_SELECTED_RECORDS:
      return removeFromSeletecRrecord(state, action);
    // case types.ADD_POLYGONS:
    //   return addPolygons(state, action);
    // case types.SET_POLYGONS:
    //   return setPolygons(state, action);

    default:
      return result;
  }
}  

function removeFromSeletecRrecord(state, action) {
 
  const cerca = action.cerca; 
    
  let selectedRecords = [];
  if(state.selectedRecords && state.selectedRecords.length>0){
    selectedRecords = state.selectedRecords.filter((i)=>i.name != cerca.name)
  } 
  return Object.assign({}, state, { selectedRecords });

}

function setUpdateRecordEdit(state, action) {

  const records = state.records|| [];
 
  const selectedRecordEdit = state.selectedRecordEdit;
  if(!selectedRecordEdit)
    return state;
   
  for (let i = 0; i < records.length; i++) {
    if (records[i].name === selectedRecordEdit.name) {
      records[i]=selectedRecordEdit;
    }
  } 
  // let selectedRecords = [];
  // if(state.selectedRecords && state.selectedRecords.length>0){
  //   selectedRecords = state.selectedRecords.filter((i)=>i.name != selectedRecordEdit.name)
  // }
  return Object.assign({}, state, { records});

}

function selectedRecordEdit(state, action) { 
  return Object.assign({}, state, { selectedRecordEdit: action.cerca });
}

function setPolygonsSelectedRecords(state, action) { 
  return Object.assign({}, state, { selectedRecords: action.selectedRecords });
}

function inputCercaNameChange(state, action) {
  const inputCercaName = action.inputCercaName;
  return Object.assign({}, state, { inputCercaName });
}

function addNewCerca(state, action) {

  const records = state.records|| [];
  const inputCercaName = String(state.inputCercaName).trim();
  if (!inputCercaName || inputCercaName.length === 0) {
    return state;
  }
  for (let i = 0; i < records.length; i++) {
    if (records[i].name === inputCercaName) {
      return state;
    }
  }
   var selectedRecord = { name: inputCercaName,  tipo: "VIRTUAL_FENCE", polygonCoordinates: [] };
  records.push(selectedRecord);

  return Object.assign({}, state, { records });

}


const reducer = new Reducer(initialState, types);
