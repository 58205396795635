
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { InputSwitch } from 'primereact/inputswitch';


export const VehicleForm = (props) => {
    console.log('VehicleForm');
    const record = props.record;

    return (
        <div className="vehicle-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label >Disponível para embarques</label>
                            <InputSwitch
                                checked={props.record.available}
                                onChange={props.changeHandler}
                                name="available"
                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Placa</label>
                            <InputText value={props.record.plate} onChange={props.changeHandler} name="plate" maxLength="7" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Renavam</label>
                            <InputText value={props.record.numberRenavam} onChange={props.changeHandler} name="numberRenavam" maxLength="11" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Capacidade</label>
                            <InputText value={props.record.vehicleCapacity} onChange={props.changeHandler} name="vehicleCapacity" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Tipo de veículo</label>
                            <LookupField
                                value={record.vehicleType}
                                placeholder="Tipo de veículo"
                                name="name"
                                field="name"
                                onComplete={props.lookupVehicleTypeOnComplete}
                                onInputFilter={props.lookupVehicleTypeOnInputFilter}
                                editDisabled={props.lookupVehicleType.editDisabled}
                                onEditField={props.lookupVehicleTypeOnEdit}
                                onClick={props.lookupVehicleTypeOnClick}

                                visible={props.lookupVehicleType.visible}
                                modal={props.lookupVehicleType.modal}
                                header={props.lookupVehicleType.header}
                                onHide={props.lookupVehicleTypeOnHide}
                                onConfirm={props.lookupVehicleTypeOnConfirm}
                                onCancel={props.lookupVehicleTypeOnCancel}
                                records={props.lookupVehicleType.records}
                                columns={props.lookupVehicleType.columns}
                                totalRecords={props.lookupVehicleType.totalRecords}
                                sortOrder={props.lookupVehicleType.order}
                                sortField={props.lookupVehicleType.sortField}
                                first={props.lookupVehicleType.first}
                                rows={props.lookupVehicleType.rows}
                                onPage={props.lookupVehicleTypeOnPage}
                                onFilter={props.lookupVehicleTypeOnFilter}
                                onSort={props.lookupVehicleTypeOnOnSort}

                            />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Motorista</label>
                            <LookupField
                                value={record.driver}
                                placeholder="Motorista"
                                name="name"
                                field="name"
                                onComplete={props.lookupDriverOnComplete}
                                onInputFilter={props.lookupDriverOnInputFilter}
                                editDisabled={props.lookupDriver.editDisabled}
                                onEditField={props.lookupDriverOnEdit}
                                onClick={props.lookupDriverOnClick}

                                visible={props.lookupDriver.visible}
                                modal={props.lookupDriver.modal}
                                header={props.lookupDriver.header}
                                onHide={props.lookupDriverOnHide}
                                onConfirm={props.lookupDriverOnConfirm}
                                onCancel={props.lookupDriverOnCancel}
                                records={props.lookupDriver.records}
                                columns={props.lookupDriver.columns}
                                totalRecords={props.lookupDriver.totalRecords}
                                sortOrder={props.lookupDriver.order}
                                sortField={props.lookupDriver.sortField}
                                first={props.lookupDriver.first}
                                rows={props.lookupDriver.rows}
                                onPage={props.lookupDriverOnPage}
                                onFilter={props.lookupDriverOnFilter}
                                onSort={props.lookupDriverOnOnSort}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};