import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import apiService from '../../services/api.service';
import { messageActions } from '../../store/message';
const BASE_PATH = '/lognex/cerca-virtual';
const crud = build(validate, BASE_PATH);
const prefix = 'crudCercaVirtual';

const actions = new Action(prefix, crud);

const newTypes = {
    SET_INPUT_CERCA_NAME: `${prefix}inputCercaNameChange`,
    ADD_NEW_CERCA: `${prefix}addNewCerca`,
    SET_SELECTED_RECORDS: `${prefix}setPolygonsSelectedRecords`,
    SAVE_CERCA_VIRTUAL: `${prefix}saveCercaVirtual`,
    REMOVE_CERCA_VIRTUAL: `${prefix}removeCercaVirtual`,
    LOAD_ALL: `${prefix}loadAll`,
    SET_RECORD_TO_EDIT: `${prefix}selectedRecordEdit`,
    UPDATE_RECORD_IN_EDIT: `${prefix}setUpdateRecordEdit`,
    REMOVE_RECORD_FROM_SELECTED_RECORDS: `${prefix}removeFromSeletecRrecord`, 
}

 
const types = Object.assign({}, actions.types, newTypes);
 

actions.setUpdateRecordEdit=()=>{
    return {
        type: types.UPDATE_RECORD_IN_EDIT,
    }
}
actions.setPolygonsSelectedRecords = (selectedRecords) => {
    return {
        type: types.SET_SELECTED_RECORDS,
        selectedRecords
    }
}

actions.inputCercaNameChange = (inputCercaName) => {
    return {
        type: types.SET_INPUT_CERCA_NAME,
        inputCercaName
    }
}

actions.addNewCerca = () => {
    return {
        type: types.ADD_NEW_CERCA
    }
}

actions.selectedRecordEdit = (cerca) => {
    return {
        type: types.SET_RECORD_TO_EDIT,
        cerca
    }
}
actions.removeFromSeletecRrecord = (cerca) => {
    return {
        type: types.REMOVE_RECORD_FROM_SELECTED_RECORDS,
        cerca
    }
}



actions.loadAll = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(actions.load({
                callback: (err, data) => {
                    if (data) {
                        dispatch(actions.setRecords(data));
                    } else {
                        dispatch(actions.setRecords([]));
                    }
                }
            }));
        } catch (error) {
            console.log('error:', error);
            dispatch(messageActions.messageShowMessages([{ message: 'Erro ao carregar os dados', type: 'error' }]));

            dispatch(actions.setRecords([]));
        }
    }
}
actions.removeCercaVirtual = (cerca) => {
    return async (dispatch, getState) => {
        try {
            //remover cerca virtual da records
            if (!cerca.oid || cerca.oid === 0) {
                const state = getState();
                const { records } = state.crudCercaVirtualState;
                const index = records.findIndex((item) => item.name === cerca.name);
                records.splice(index, 1);
                 
                dispatch(actions.setRecords(records));
                  dispatch(actions.removeFromSeletecRrecord(cerca));
            } else {
                console.log('removendo cerca:', cerca);
                await apiService.remove(`/api/v1${BASE_PATH}/${cerca.oid}`);
                dispatch(actions.load({
                    callback: (err, data) => {

                        if (data) {
                            dispatch(actions.setRecords(data));
                        } else {
                            dispatch(actions.setRecords([]));
                        }
                    }
                }));
            }
        } catch (error) {
            console.log('error:', error);
            dispatch(messageActions.messageShowMessages([{ message: 'Erro ao remover a cerca virtual', type: 'error' }]));

            dispatch(actions.setRecords([]));
        }
    }
}

//salvar cerce virtual
actions.saveCercaVirtual = () => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const { records } = state.crudCercaVirtualState;

            if (records && records.length > 0) {
                for (let i = 0; i < records.length; i++) { 
                    if (records[i].oid && records[i].oid > 0) {
                        await apiService.put(`/api/v1${BASE_PATH}/${records[i].oid}`, records[i]);
                    }else{
                        await apiService.post(`/api/v1${BASE_PATH}`, records[i]);
                    }
                }               
            }
          
        } catch (error) {
          console.log('error:', error);
        }finally{
            dispatch(actions.setPolygonsSelectedRecords([]))
            dispatch(actions.loadAll());
        }
    }
}

export { types, actions };