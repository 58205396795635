import React from 'react';
import { Card } from "primereact/card";
import OccurrenceTypeForAppListContainer from './OccurrenceTypeForAppListPageContainer';

export const OccurrenceTypeForAppListPage = (props) => {
  return (
    <Card>
      <OccurrenceTypeForAppListContainer />
    </Card>
  );
}