import moment from "moment-timezone"
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { ButtonDialog } from "../../../../../../components/button-dialog/ButtonDialog";
import FileUploaderButton from "../../../../../../components/file-uploader-button/FileUploaderButton";
import "./workDayUnidade.scss";

export class WorkDayConfigUnidade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: moment().set("hour", 8).set("minute", 0).toDate(),
      endTime: moment().set("hour", 18).set("minute", 0).toDate(),
      showModalHours: false,
      showModalHoliday: false,
      holiday: null,
      description: "",
    };
  }
  changeStartTime = (e) => {
    this.setState({ startTime: e.target.value });
  };
  changeEndTime = (e) => {
    this.setState({ endTime: e.target.value });
  };

  onHide = () => {
    this.setState({ showModalHours: false });
  };
  changeHoliday = (e) => {
    const dateC = new Date(e.target.value);
    //dia 
    const day = dateC.getDate();
    const month = dateC.getMonth();
    const year = dateC.getFullYear();
    // Cria uma data no fuso horário UTC
    const dateUTC = new Date(Date.UTC(year, month, day, 11, 0, 0));

    this.setState({ holiday: dateUTC });
  };

  changeDescription = (e) => {
    this.setState({ description: e.target.value });
  };

  render() {
    const onClickBtnAdd = () => {
      this.setState({ showModalHours: true });
    };
    const onClickBtnAddHoliday = () => {
      this.setState({ showModalHoliday: true });
    };

    const onConfirm = () => {
      this.props.setWorkDayTimeRangeTime(this.state.startTime, this.state.endTime);
      this.setState({ endTime: "", startTime: "", showModalHours: false });
    };

    const onConfirmCadHoliday = () => {
      this.props.setDateHoliday({ holiday: this.state.holiday, description: this.state.description });
      this.setState({ description: "", holiday: null, showModalHoliday: false });
    };

    const onCancel = () => {
      this.setState({ endTime: "", startTime: "", showModalHours: false });
      this.setState({ description: "", holiday: null, showModalHoliday: false });
    };

    const rangeTimeColumns = [
      {
        field: "start",
        header: "Início",
        columnKey: "start",
        sortable: false,
        filter: false,
      },
      {
        field: "end",
        header: "Fim",
        columnKey: "end",
        sortable: false,
        filter: false,
      },
    ];
    const feriadosColumns = [
      {
        field: "holiday",
        header: "Feriados",
        columnKey: "holiday",
        sortable: false,
        filter: false,
      },
      {
        field: "description",
        header: "Descrição",
        columnKey: "description",
        sortable: false,
        filter: false,
      },
    ];

    const columns = [
      <Column key="selectorColumnTime" selectionMode="single" style={{ width: "3em" }} />,
      ...rangeTimeColumns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];

    const holidayTemplate = (rowData, column) => {
      try {
        const date = rowData.holiday; //data da ultima entrega realizada
        let formattedDate = "";
        if (date) {
          formattedDate = moment.tz(date, "America/Sao_Paulo").format("DD/MM/YYYY");
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const columnsFeriados = [
      <Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />,
      ...feriadosColumns.map((col) => {
        if (col.field == "holiday") {
          return <Column {...col} key={col.field} body={holidayTemplate} style={{ width: "12em" }} />;
        }
        return <Column {...col} key={col.field} />;
      }),
    ];

    const timeRecords = this.props.docaWorkDayTime ? this.props.docaWorkDayTime.docaRangeTime : [];
    const holidaysRecords = this.props.docaWorkDayTime ? this.props.docaWorkDayTime.holidays : [];

    const footerCloseModal = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={onConfirm}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={onCancel}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    const footerCloseHolydayModal = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={onConfirmCadHoliday}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={onCancel}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    return (
      <div className={"panel-categoria"}>
        <div className="p-grid">
          <div className="p-col-12">
            <h3>Configuração dos dias e horários de trabalho</h3>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-6  align-bottom-left">
            <label style={{ marginRight: ".30em" }}> Habilitar o gerenciamento de DOCAS </label>
            <InputSwitch disabled={!this.props.selectedBusinessUnitRecord} checked={this.props.docaWorkDayTime.useDocks || false} onChange={this.props.setDocaWorkDayTimeUseDocks} />
          </div>
        </div>
        {/* horarios */}
        <div className="p-grid">
          <div className="p-col-6  align-bottom-right">
            <label></label>
            <button className={"lognex-btn-highlighted"} disabled={!this.props.selectedBusinessUnitRecord} onClick={(e) => onClickBtnAdd()} title="Novo" style={{ marginRight: ".25em" }}>
              <i className="pi pi-plus"></i>
            </button>

            <ButtonDialog
              onClick={this.props.removeSelectedRangeTimeRecord}
              button={{
                title: "Remover",
                className: "lognex-btn-danger",
                disabled: !this.props.selectedBusinessUnitRecord || !this.props.selectedRangeTimeRecord,
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente remover o registro?",
              }}
            ></ButtonDialog>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-6">
            <DataTable
              selectionMode="single"
              value={timeRecords}
              lazy={true}
              paginator={false}
              emptyMessage="Não foram encontrados registros"
              metaKeySelection={false}
              selection={this.props.selectedRangeTimeRecord}
              onSelectionChange={this.props.setSelectedRangeTimeRecord}
            >
              {columns}
            </DataTable>
          </div>
        </div>
        {/* horarios */}

        {/* tempos */}
        <div className="p-grid" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
          <div className="p-col-4 form-field-container">
            <label>Intervalo entre operações(Minutos)</label>
            <InputText
              disabled={!this.props.selectedBusinessUnitRecord}
              value={this.props.docaWorkDayTime.timeNextOperation || 0}
              type="number"
              min={0}
              step={15}
              onChange={this.props.changeTimeNextOperation}
            />
          </div>
          <div className="p-col-4 form-field-container">
            <label>Agendar após (Dias úteis)</label>
            <InputText
              disabled={!this.props.selectedBusinessUnitRecord}
              value={this.props.docaWorkDayTime.dayStartSchedule || 0}
              type="number"
              min={0}
              step={1}
              onChange={this.props.changeDayStartSchedule}
            />
          </div>

          <div className="p-col-4 form-field-container">
            <label>Mostrar horários até (Dias úteis)</label>
            <InputText
              disabled={!this.props.selectedBusinessUnitRecord}
              value={this.props.docaWorkDayTime.rangeDaysSchedule || 0}
              type="number"
              min={0}
              step={1}
              onChange={this.props.changeRangeDaysSchedule}
            />
          </div>

        </div>
        {/* tempos */}

        <div className="p-grid">
          <div className="p-col-12">
            <label>Habilite os dias de trabalho</label>
          </div>
          <div className="p-col" style={{ display: "flex", flexWrap: "wrap", paddingTop: 0 }}>
            <div className="align-inline-center">
              <label>Segunda-feira</label>
              <div className="space-10px"></div>
              <InputSwitch checked={this.props.docaWorkDayTime.docaWorkDays.monday} onChange={this.props.onChangeWorkDay} name={"monday"} />
            </div>

            <div className="align-inline-center">
              <label>Terça-feira</label>
              <div className="space-10px"></div>
              <InputSwitch checked={this.props.docaWorkDayTime.docaWorkDays.tuesday} onChange={this.props.onChangeWorkDay} name={"tuesday"} />
            </div>

            <div className="align-inline-center">
              <label>Quarta-feira</label>
              <div className="space-10px"></div>
              <InputSwitch checked={this.props.docaWorkDayTime.docaWorkDays.wednesday} onChange={this.props.onChangeWorkDay} name={"wednesday"} />
            </div>

            <div className="align-inline-center">
              <label>Quinta-feira</label>
              <div className="space-10px"></div>
              <InputSwitch checked={this.props.docaWorkDayTime.docaWorkDays.thursday} onChange={this.props.onChangeWorkDay} name={"thursday"} />
            </div>

            <div className="align-inline-center">
              <label>Sexta-feira</label>
              <div className="space-10px"></div>
              <InputSwitch checked={this.props.docaWorkDayTime.docaWorkDays.friday} onChange={this.props.onChangeWorkDay} name={"friday"} />
            </div>

            <div className="align-inline-center">
              <label>Sábado</label>
              <div className="space-10px"></div>
              <InputSwitch checked={this.props.docaWorkDayTime.docaWorkDays.saturday} onChange={this.props.onChangeWorkDay} name={"saturday"} />
            </div>

            <div className="align-inline-center">
              <label>Domingo</label>
              <div className="space-10px"></div>
              <InputSwitch checked={this.props.docaWorkDayTime.docaWorkDays.sunday} onChange={this.props.onChangeWorkDay} name={"sunday"} />
            </div>
          </div>
        </div>

        {/* Feriados */}
        <div className="p-grid">
          <div className="p-col-6  align-bottom-right">
            <label></label>
            <FileUploaderButton
              buttonClass={"lognex-btn-highlighted-inverse"}
              title={"Importar cadastro de feriados"}
              accept=".xlsx,.xls"
              disabled={!this.props.selectedBusinessUnitRecord}
              onUpload={this.props.onClickImportHolidayXLSX}
            ></FileUploaderButton>

            <button className={"lognex-btn-highlighted"} disabled={!this.props.selectedBusinessUnitRecord} onClick={(e) => onClickBtnAddHoliday()} title="Novo" style={{ marginRight: ".25em" }}>
              <i className="pi pi-plus"></i>
            </button>

            <ButtonDialog
              onClick={this.props.removeSelectedHolidayRecord}
              button={{
                title: "Remover",
                className: "lognex-btn-danger",
                disabled: !this.props.selectedBusinessUnitRecord || !this.props.selectedHolidayRecord,
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente remover o registro?",
              }}
            ></ButtonDialog>

            <button
              style={{ marginRight: ".25em" }}
              className={"lognex-btn-highlighted-inverse"}
              disabled={!this.props.selectedBusinessUnitRecord}
              onClick={(e) => this.props.onDownloadHolidayTemplate()}
            >
              <i style={{ marginTop: "6px" }} className="pi pi-download"></i>
            </button>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-6">
            <DataTable
              selectionMode="single"
              value={holidaysRecords}
              lazy={true}
              paginator={false}
              emptyMessage="Não foram encontrados registros"
              metaKeySelection={false}
              selection={this.props.selectedHolidayRecord}
              onSelectionChange={this.props.setSelectedHolidayRecord}
            >
              {columnsFeriados}
            </DataTable>
          </div>
        </div>
        {/* Feriados */}
        {this.state.showModalHours && (
          <Dialog
            header={"Intervalo de trabalho"}
            footer={footerCloseModal}
            visible={this.state.showModalHours}
            style={{ width: "400px" }}
            className="dialog-content"
            modal={true}
            onHide={this.onHide}
          >
            <div className="p-grid">
              <div className="p-col-6 form-field-container">
                <label>Início</label>
                <Calendar value={this.state.startTime} onChange={this.changeStartTime} timeOnly={true} disabled={!this.props.selectedBusinessUnitRecord} hourFormat="12" />
              </div>
              <div className="p-col-6 form-field-container">
                <label>Fim</label>
                <Calendar value={this.state.endTime} onChange={this.changeEndTime} disabled={!this.props.selectedBusinessUnitRecord} timeOnly={true} hourFormat="12" />
              </div>
            </div>
          </Dialog>
        )}

        {this.state.showModalHoliday && (
          <Dialog header={"Feriados"} footer={footerCloseHolydayModal} visible={this.state.showModalHoliday} style={{ width: "600px" }} className="dialog-content" modal={true} onHide={this.onHide}>
            <div className="p-grid">
              <div className="p-col-3 form-field-container">
                <label>Data do feriado</label>
                <Calendar dateFormat="dd/mm/yy" value={this.state.holiday} onChange={this.changeHoliday} showIcon={true} />
              </div>
              <div className="p-col-9 form-field-container">
                <label>Descrição</label>
                <InputText type="text" value={this.state.description} onChange={this.changeDescription} />
              </div>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}
