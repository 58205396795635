import moment from 'moment';
import { Reducer } from "../../../services/reducer.factory";
import { types } from "./cargo-shipment.actions";

const initialState = {

    lookupCarrier: {
        modal: false,
        visible: false,
        header: 'Transportadora'
    },
    lookupDriver: {
        modal: false,
        visible: false,
        header: 'Motorista'
    },
    lookupVehicleType: {
        modal: false,
        visible: false,
        header: 'Tipo Veículo'
    },
    lookupOrder: {
        modal: false,
        visible: false,
        header: 'Pedidos',
        index: 0,
        max: 15,
        count: 0,
        sortField: 0,
        sortOrder: 0,
        editDisabled: true
    },
    orderFilters: [],
    orderColumns: [
        {
            field: "customFields.shipmentCode",
            header: "Shipment",
            columnKey: "customFields.shipmentCode",
            sortable: false,
            filter: false
        },
        {
            field: "depotCode",
            header: "Unidade",
            columnKey: "depotCode",
            sortable: false,
            filter: false
        },
        {
            field: "innerID",
            header: "Código Interno",
            columnKey: "innerID",
            sortable: false,
            filter: false
        },
        {
            field: "code",
            header: "Código",
            columnKey: "code",
            sortable: false,
            filter: false
        },
        {
            field: "ag",
            header: "Tipo",
            columnKey: "ag",
            sortable: false,
            filter: false
        },
        {
            field: "sender.address.city",
            header: "Origem",
            columnKey: "sender.address.city",
            sortable: false,
            filter: false
        },
        {
            field: "receiver.address.city",
            header: "Destino",
            columnKey: "receiver.address.city",
            sortable: false,
            filter: false
        },
        {
            field: "receiver.name",
            header: "Cliente",
            columnKey: "receiver.name",
            sortable: false,
            filter: false
        },
        {
            field: "cargo.summary.weight",
            header: "Peso",
            columnKey: "cargo.summary.weight",
            sortable: false,
            filter: false
        },
        {
            field: "cargo.summary.value",
            header: "Valor",
            columnKey: "cargo.summary.value",
            sortable: false,
            filter: false
        },
        {
            field: "nfeNumber",
            header: "NFe",
            columnKey: "nfeNumber",
            sortable: false,
            filter: false
        },
        {
            field: "deliveryForecast",
            header: "Prazo Entrega",
            columnKey: "deliveryForecast",
            sortable: false,
            filter: false
        },
        {
            field: "apportionmentValue",
            header: "Frete",
            columnKey: "apportionmentValue",
            sortable: false,
            filter: false
        },
    ],
    occurrenceColumns: [
        {
            field: "orderCode",
            header: "Pedido",
            columnKey: "orderCode",
            sortable: false,
            filter: false
        },
        {
            field: "dateOccurrence",
            header: "Data da Ocorrência",
            columnKey: "dateOccurrence",
            sortable: false,
            filter: false
        },
        {
            field: "code",
            header: "Tipo da Ocorrência",
            columnKey: "code",
            sortable: false,
            filter: false
        },
        {
            field: "description",
            header: "Descrição",
            columnKey: "description",
            sortable: false,
            filter: false
        },
        {
            field: "image",
            header: "Comprovante Entrega",
            columnKey: "image",
            sortable: false,
            filter: false
        }
    ],
    orders: [],
    selectedOrders: [],
    cards: {
        totalWeight: 0,
        totalValue: 0,
        totalFreight: 0,
        totalWeightVehicle: 0,
    },
    currentRecord: {
        oid: "",
        code: "",
        status: "",
        type: "",
        vehicle: {},
        quote: {
            totalValue: 0.0
        },
        name: "",
        orders: [],
        occurrences: [],
        obs: "",
        freightCombinedShipment: null,
        freightCombinedOrder: 0,
        freightCombinedCheck: false,
        freightOld: null
    },
    deliveryStoColumns: [
        {
            field: "customFields.shipmentCode",
            header: "Shipment",
            columnKey: "customFields.shipmentCode",
            sortable: false,
            filter: false
        },
        {
            field: "depotCode",
            header: "Unidade",
            columnKey: "depotCode",
            sortable: false,
            filter: false
        },
        {
            field: "innerID",
            header: "Código Interno",
            columnKey: "innerID",
            sortable: false,
            filter: false
        },
        {
            field: "code",
            header: "Código",
            columnKey: "code",
            sortable: false,
            filter: false
        },
        {
            field: "codeVenda",
            header: "Código Venda",
            columnKey: "codeVenda",
            sortable: false,
            filter: false
        },
        {
            field: "sender.address.city",
            header: "Origem",
            columnKey: "sender.address.city",
            sortable: false,
            filter: false
        },
        {
            field: "receiver.address.city",
            header: "Destino",
            columnKey: "receiver.address.city",
            sortable: false,
            filter: false
        },
        {
            field: "receiver.name",
            header: "Cliente",
            columnKey: "receiver.name",
            sortable: false,
            filter: false
        },
        {
            field: "cargo.summary.weight",
            header: "Peso",
            columnKey: "cargo.summary.weight",
            sortable: false,
            filter: false
        },
        {
            field: "cargo.summary.value",
            header: "Valor",
            columnKey: "cargo.summary.value",
            sortable: false,
            filter: false
        },
        {
            field: "nfeNumber",
            header: "NFe",
            columnKey: "nfeNumber",
            sortable: false,
            filter: false
        },
        {
            field: "deliveryForecast",
            header: "Prazo Entrega",
            columnKey: "deliveryForecast",
            sortable: false,
            filter: false
        },
        {
            field: "apportionmentValue",
            header: "Frete",
            columnKey: "apportionmentValue",
            sortable: false,
            filter: false
        },
    ]
    // quotesColumns:[
    //     {
    //         field: "carrier",
    //         header: "Transportadora",
    //         columnKey: "carrier",
    //         sortable: false,
    //         filter: false
    //     },
    //     {
    //         field: "quote",
    //         header: "Frete",
    //         columnKey: "quote",
    //         sortable: false,
    //         filter: false
    //     },
    //     {
    //         field: "icms",
    //         header: "ICMS",
    //         columnKey: "icms",
    //         sortable: false,
    //         filter: false
    //     },
    //     {
    //         field: "totalQuote",
    //         header: "Frete Total",
    //         columnKey: "totalQuote",
    //         sortable: false,
    //         filter: false
    //     }
    // ],
    // freightQuotesRecords: [],
};

const reducer = new Reducer(initialState, types);

export function CRUDCargoShipmentReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        case types.LOOKUP_CARRIER_SET_VISIBLE:
            result = lookupCarrierSetVisible(state, action);
            break;
        case types.LOOKUP_DRIVER_SET_VISIBLE:
            result = lookupDriverSetVisible(state, action);
            break;
        case types.LOOKUP_VEHICLE_TYPE_SET_VISIBLE:
            result = lookupVehicleTypeSetVisible(state, action);
            break;
        case types.SET_SELECTED_ORDERS:
            result = setSelectedOrders(state, action);
            break;
        case types.REMOVE_SELECTED_ORDERS:
            result = removeSelectedOrders(state, action);
            break;
        case types.LOOKUP_ORDER_SET_VISIBLE:
            result = lookupOrderSetVisible(state, action);
            break;
        case types.LOOKUP_ORDER_SET_INDEX:
            result = lookupOrderSetIndex(state, action);
            break;
        case types.LOOKUP_ORDER_SET_MAX:
            result = lookupOrderSetMax(state, action);
            break;
        case types.LOOKUP_ORDER_SET_COUNT:
            result = lookupOrderSetCount(state, action);
            break;
        case types.LOOKUP_ORDER_SET_SORT_FIELD:
            result = lookupOrderSetSortField(state, action);
            break;
        case types.LOOKUP_ORDER_SET_SORT_ORDER:
            result = lookupOrderSetSortOrder(state, action);
            break;
        case types.SET_ORDER_FILTERS:
            result = setOrderFilters(state, action);
            break;
        case types.SET_ORDERS:
            result = setOrders(state, action);
            break;
        case types.ADD_ORDERS:
            result = addOrders(state, action);
            break;
        case types.SET_CARD_VALUES:
            result = setCardsValue(state, action);
            break;
        case types.SET_CARGO_SHIPMENT:
            result = setCargoShipment(state, action);
            break;
        case types.SET_FREIGHT_COMBINED_CHECK:
            result = setFreightCombinedCheck(state, action);
            break;
        case types.SET_FREIGHT_COMBINED_SHIPMENT:
            result = setFreightCombinedShipment(state, action);
            break;
        case types.CALCULATE_CARGO_SHIPMENT_WITH_FREIGHT_COMBINED_ORDER:
            result = calculateCargoShipmentWithFreightCombinedOrder(state, action);
        case types.SET_FREIGHT_COMBINED_ORDER:
            result = setFreightCombinedOrder(state, action);
            break;
        case types.CALC_FREIGHT_COMBINED_ORDERS:
            result = setCalcFreightCombinedOrdersWithShipmentFreight(state, action);
            break;
        case types.SET_FREIGHT_TOTAL_VALUE:
            result = setFreightTotalValue(state, action);
            break;
        default:
            result = state;
            break;
    }
    return result;
}


function lookupCarrierSetVisible(state, action) {
    const lookupCarrier = { ...state.lookupCarrier, visible: action.visible };
    return { ...state, lookupCarrier }
}
function lookupDriverSetVisible(state, action) {
    const lookupDriver = { ...state.lookupDriver, visible: action.visible };
    return { ...state, lookupDriver }
}
function lookupVehicleTypeSetVisible(state, action) {
    const lookupVehicleType = { ...state.lookupVehicleType, visible: action.visible };
    return { ...state, lookupVehicleType }
}
function setSelectedOrders(state, action) {
    const selectedOrders = [...action.orders];
    return { ...state, selectedOrders }
}
function removeSelectedOrders(state, action) {
    let selectedOrders = state.selectedOrders || [];
    let currentRecord = state.currentRecord;
    let orders = currentRecord.orders || [];
    orders = orders.filter(order => {
        const selected = selectedOrders.find(element => element.oid === order.oid);
        return !(selected != null);
    });
    currentRecord = { ...currentRecord, orders }
    selectedOrders = [];
    return { ...state, currentRecord, selectedOrders }
}
function lookupOrderSetVisible(state, action) {
    const lookupOrder = { ...state.lookupOrder, visible: action.visible };
    return { ...state, lookupOrder }
}
function lookupOrderSetIndex(state, action) {
    const lookupOrder = { ...state.lookupOrder, index: action.index };
    return { ...state, lookupOrder }
}
function lookupOrderSetMax(state, action) {
    const lookupOrder = { ...state.lookupOrder, max: action.max };
    return { ...state, lookupOrder }
}
function lookupOrderSetCount(state, action) {
    const lookupOrder = { ...state.lookupOrder, count: action.count };
    return { ...state, lookupOrder }
}
function lookupOrderSetSortField(state, action) {
    const lookupOrder = { ...state.lookupOrder, sortField: action.sortField };
    return { ...state, lookupOrder }
}
function lookupOrderSetSortOrder(state, action) {
    const lookupOrder = { ...state.lookupOrder, sortOrder: action.sortOrder };
    return { ...state, lookupOrder }
}

function setOrderFilters(state, action) {
    const orderFilters = [...action.filters];
    return { ...state, orderFilters }
}
function setOrders(state, action) {
    const orders = [...action.orders];
    return { ...state, orders }
}
function addOrders(state, action) {
    let currentRecord = state.currentRecord;
    let orders = [...currentRecord.orders] || [];
    let newOrders = action.orders || []
    newOrders.forEach(order => {
        let current = orders.find(element => element.oid === order.oid);
        if (!current) {
            orders.push(order);
        }
    });
    orders.sort((o1, o2) => {
        if (o1.code < o2.code) {
            return -1;
        } else if (o1.code === o2.code) {
            return 0;
        } else {
            return 1;
        }
    })
    currentRecord = { ...currentRecord, orders };
    return { ...state, currentRecord }
}
function setCardsValue(state, action) {
    let currentRecord = state.currentRecord;
    const orders = state.currentRecord.orders;
    let totalWeight = 0;
    let totalValue = 0;
    let totalFreight = 0;

    // currentRecord.freightOld != null ? totalFreight = currentRecord.freightOld : totalFreight = state.currentRecord.quote.totalValue;
    totalFreight = state.currentRecord.quote.totalValue

    orders.forEach(order => {
        totalWeight = totalWeight + parseFloat(order.cargo.summary.weight)
        totalValue = totalValue + parseFloat(order.cargo.summary.value)
    })

    const totalWeightVehicle = (totalWeight * 100) / state.currentRecord.vehicle.vehicleCapacity;

    return { ...state, cards: { totalWeight, totalValue, totalFreight, totalWeightVehicle } }
}
function setCargoShipment(state, action) {
    let currentRecord = state.currentRecord;
    currentRecord = { ...action.cargoShipment };
    if (currentRecord && currentRecord.pickupDate && typeof currentRecord.pickupDate === 'string') {
        currentRecord.pickupDate = moment(currentRecord.pickupDate).toDate()
    }
    return { ...state, currentRecord };
}
function setFreightCombinedCheck(state, action) {
    let currentRecord = state.currentRecord;
    let cards = state.cards;
    if (currentRecord.freightOld == null) {
        currentRecord.freightOld = currentRecord.quote.totalValue
    }

    if (action.freightCombinedCheck == false) {
        currentRecord.freightCombinedCheck = action.freightCombinedCheck;
        currentRecord.quote.totalValue = currentRecord.freightOld;
        cards.totalFreight = currentRecord.quote.totalValue;
        state = { ...state, currentRecord, cards };
        return state;
    }

    currentRecord.freightCombinedCheck = action.freightCombinedCheck;
    state = { ...state, currentRecord };
    return state;

}
function setFreightCombinedShipment(state, action) {
    let currentRecord = state.currentRecord
    currentRecord.freightCombinedShipment = parseFloat(action.freightCombinedShipment)

    state = { ...state, currentRecord };
    return state;
}
function calculateCargoShipmentWithFreightCombinedOrder(state, action) {
    let currentRecord = state.currentRecord
    let cards = state.cards;
    currentRecord.quote.totalValue = 0
    cards.totalFreight = 0
    if (currentRecord.orders) {
        for (let i = 0; i < currentRecord.orders.length; i++) {
            if (currentRecord.orders[i].freightCombinedOrder) {
                const valorComb = parseFloat(currentRecord.orders[i].freightCombinedOrder)
                currentRecord.quote.totalValue += valorComb
            }
            else {
                currentRecord.quote.totalValue += currentRecord.orders[i].apportionmentValue
            }
        }
        cards.totalFreight = parseFloat(currentRecord.quote.totalValue)
    }
    state = { ...state, currentRecord, cards };
    return state;

}
function setFreightCombinedOrder(state, action) {
    let currentRecord = state.currentRecord
    const valorFretComb = parseFloat(action.freightCombinedOrder)
    currentRecord.freightCombinedOrder = valorFretComb;

    if (currentRecord.orders) {
        for (let index = 0; index < currentRecord.orders.length; index++) {
            if (currentRecord.orders[index].code === action.orderCode) {
                currentRecord.orders[index].freightCombinedOrder = valorFretComb;
                break;
            }
        }
    }
    state = { ...state, currentRecord };
    return state;
}
function setCalcFreightCombinedOrdersWithShipmentFreight(state, action) {
    let currentRecord = state.currentRecord;
    let newFreightCombinedOrder = 0;
    const valorFretComb = parseFloat(action.freightCombinedShipment)
    if (currentRecord.orders) {
        newFreightCombinedOrder = valorFretComb / currentRecord.orders.length;
        for (let index = 0; index < currentRecord.orders.length; index++) {
            currentRecord.orders[index].freightCombinedOrder = newFreightCombinedOrder;
        }
    }
    state = { ...state, currentRecord };
    return state
}
function setFreightTotalValue(state, action) {
    let currentRecord = state.currentRecord;
    let cards = state.cards;
    if (currentRecord.freightOld == null) {
        currentRecord.freightOld = currentRecord.quote.totalValue
    }
    currentRecord.quote.totalValue = currentRecord.freightCombinedShipment
    cards.totalFreight = currentRecord.freightCombinedShipment
    state = { ...state, currentRecord, cards };
    return state;
}
