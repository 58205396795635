import React from "react";
import moment from "moment";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";


// Componentes padrão da lib
import { Card } from "primereact/card";
import { Column } from 'primereact/column';
import { DataTable } from "primereact/datatable";

// componentes customizados
import Toolbar from "../../../components/toolbar/Toolbar";
import { Page } from "../../../components/page/Page";
import { routes } from '../../../constants';
import { DriverList } from "./DriverList";

// ações e serviços
import storage from '../../../services/stoage.service';
import { actions } from '../driver.actions';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";

import './driverList.scss';



const mapStateToProps = state => {
  const { crudDriverState } = state;
  let carrier;
  const user = storage.getUser();
  if (user.carrier) {
    carrier = user.carrier
  }
  const vehicles = crudDriverState.vehicles;

  return {
    columns: crudDriverState.columns,
    records: crudDriverState.records,
    first: crudDriverState.index,
    rows: crudDriverState.max,
    totalRecords: crudDriverState.count,
    sortField: crudDriverState.sortField,
    order: crudDriverState.sortOrder,
    carrier,
    recordExpand: crudDriverState.recordExpand,
    virtualFenceHistoryColumns: crudDriverState.virtualFenceHistoryColumns,
    historyCargoShipmentColumns: crudDriverState.historyCargoShipmentColumns,
    vehicleColumns: crudDriverState.vehicleColumns,
    vehicles,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    hideDialog: () => dispatch(actions.setLogImportXLSXResult(null)),
    clearDriverSetNew: () => {
      dispatch(actions.clearDriverSetNew())
    },
    setExpandedDriverRows: (expandedDriverRows) => {
      if (expandedDriverRows && expandedDriverRows.length > 0) {
        dispatch(actions.setRecordExpand(expandedDriverRows[1]));
        // existir o documentNumber busca os veiculos
        if (expandedDriverRows[1].documentNumber) {
          dispatch(actions.loadVehicleByDriver(expandedDriverRows[1].documentNumber));
        }
      } else {
        dispatch(actions.setRecordExpand(null));
      }
    },
  }
}


class DriverListPageContainer extends React.Component {

  newHandler = () => {
    this.props.clearDriverSetNew();
    this.props.history.push(`${routes.PATH_DRIVER}/novo`)
  }


  componentDidMount() {
    this.onApplyFilter("componentDidMount");
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.onApplyFilter("onReceiveAppNotifications");
    }
  }

  onApplyFilter = (orig) => {
    const filters = [];

    if (this.props.carrier) {
      filters.push({
        campo: "carrier.cnpj",
        valor: this.props.carrier.cnpj,
        tipoFiltro: 'IGUAL_A'
      })
    }
    this.props.applyFilter(filters)
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if (key == "oid") {
        filters.push({
          campo: key,
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        })
      } else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'

        })
      }
    })
    this.props.applyFilter(filters)

  }

  onSearchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`);
  }

  errorsDialogOnHide = () => {
    this.props.hideDialog()
  }

  setExpandedDriverRows = (e) => {
    this.props.setExpandedDriverRows(e.data);
  };

  render() {

    const header = (
      <div>
        <h1>Motoristas</h1>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'
            onSearch={this.props.carrier ? this.onSearchHandler : null}
          >


          </Toolbar >
        </div>

      </div>
    );

    const formatCPF = (cpf) => {
      if (!cpf || cpf.lenth < 11) {
        return cpf;
      }
      const mask = "###.###.###-##"
      let index = 0;
      const data = mask.split('').map(it => {
        if (it === '#') {
          return cpf[index++]
        } else {
          return it
        }
      })
      let formated = data.join('');
      return formated
    }

    const cpfTemplate = (rowData, column) => {
      return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>{formatCPF(rowData.documentNumber)}</span>
      )
    }



    const oidTemplate = (rowData, column) => {
      return (
        <div className="id-link"><Link to={`${routes.PATH_DRIVER}/${rowData.oid}`}>{rowData.oid}</Link></div>
      )
    }

    const columns = this.props.columns.filter(col => col.field == "oid" || col.field == "name" || col.field == "documentNumber" || col.field == "carrier.cnpj").map(col => {

      if (col.field === 'documentNumber') {
        return (<Column {...col} key={col.field} body={cpfTemplate} />);
      }

      if (col.field === 'oid') {
        return (<Column {...col} key={col.field} body={oidTemplate} />);
      }

      return (<Column {...col} key={col.field} />)
    })

    const formatDate = (value) => {
      if (value) {
        const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
        return formattedDate;
      }
    }

    const dataTemplate = (rowData, column) => {
      const value = rowData[column.field];
      if (value) {
        const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
        return <div style={{ textAlign: "center" }}>{formattedDate}</div>;
      } else {
        // Se não houver data, retorna um valor padrão
        return <div style={{ textAlign: "center" }}>Data inválida</div>;
      }

    };

    const statusTemplate = (rowData, column) => {
      const status = rowData[column.field];
      // Verifica se o status é "removido" exibe em vermelho
      const statusStyle = status === "removido" ? { color: "red", textAlign: "center" } : { textAlign: "center" };

      return (
        <div style={statusStyle}>
          {status}
        </div>
      );
    }

    const typeTemplate = (rowData, column) => {
      const value = rowData[column.field];
      // Verifica se o campo é o "type" e formata o texto conforme o tipo
      if (column.field === "type" && value) {
        let formattedType = "";

        switch (value) {
          case "VIRTUAL_FENCE":
            formattedType = "Cerca Virtual";
            break;
          case "GEO_FENCE":
            formattedType = "Cerca Geográfica";
            break;
          default:
            formattedType = value; // Se não for nenhum tipo conhecido, exibe o valor original
        }

        return <div style={{ textAlign: "center" }}>{formattedType}</div>;

      };
    };

    const centeredTemplate = (rowData, column) => {
      return <div style={{ textAlign: "center" }}>{rowData[column.field]}</div>;
    };

    const formatTextType = (type) => {
      switch (type) {
        case "VIRTUAL_FENCE":
          return "Cerca Virtual";
        case "GEO_FENCE":
          return "Cerca Geográfica";
        // Adicione outros tipos de cerca se necessário
        default:
          return type || "N/A"; // Caso não seja nenhum tipo conhecido, retorna o tipo original ou "N/A"
      }
    };

    const templateDriverTbExpandedRow = (rowData, e) => {

      const currentFence = [];
      const currentShipments = []// rowData.cargoShipment || {};
      const historicoEmbar = []
      const historicoCerca = []
      if (this.props.vehicles && this.props.vehicles.length > 0) {
        for (let i = 0; i < this.props.vehicles.length; i++) {
          const vehicle = this.props.vehicles[i];
          if (vehicle.virtualFenceCurrent) {
            const element = vehicle.virtualFenceCurrent;
            currentFence.push({
              name: element.name || "",
              type: element.type || "",
              includeDate: element.includeDate ? formatDate(element.includeDate) : null,
            });
          }
          if (vehicle.cargoShipment) {
            currentShipments.push({
              id: vehicle.cargoShipment ? vehicle.cargoShipment.id : null,
              virtualFenceName: vehicle.cargoShipment ? vehicle.cargoShipment.virtualFenceName : "",
              virtualFenceId: vehicle.cargoShipment ? vehicle.cargoShipment.virtualFenceId : null,
              plate: vehicle.cargoShipment ? vehicle.cargoShipment.plate : "",
              createdAt: vehicle.cargoShipment ? formatDate(vehicle.cargoShipment.createdAt) : null,
            });
          }
          if (vehicle.cargoShipmentHistory && vehicle.cargoShipmentHistory.length > 0) {
            for (let j = 0; j < vehicle.cargoShipmentHistory.length; j++) {
              const element = vehicle.cargoShipmentHistory[j];
              historicoEmbar.push({
                id: element.id || null,
                virtualFenceName: element.virtualFenceName || "",
                virtualFenceId: element.virtualFenceId || "",
                plate: element.plate || "",
                createdAt: element.createdAt ? formatDate(element.createdAt) : null,
                dateFinish: element.dateFinish ? element.dateFinish : null,
                status: element.status || "",
              });
            }
          }

          if (vehicle.virtualFenceHistory) {
            for (let j = 0; j < vehicle.virtualFenceHistory.length; j++) {
              const element = vehicle.virtualFenceHistory[j];
              historicoCerca.push({
                oid: element.oid || null,
                name: element.name || "",
                type: element.type || "",
                plate: vehicle.plate || "",
                label: vehicle.vehicleType ? vehicle.vehicleType.label : "",
                includeDate: element.includeDate ? formatDate(element.includeDate) : null,
              });
            }
          }
        }
      }


      const historyVirtualFenceColumnsSub = this.props.virtualFenceHistoryColumns.map((col, index) => {
        //template para data
        if (col.field === "includeDate") {
          return <Column {...col} key={col.field} body={dataTemplate} />;
        }
        if (col.field === "type") {
          return <Column {...col} key={col.field} body={typeTemplate} />;
        }
        return <Column {...col} key={col.field} body={centeredTemplate} />;
      });

      const historyCargoShipmentColumnsSub = this.props.historyCargoShipmentColumns.map((col, index) => {
        if (col.field === "dateFinish") {
          return <Column {...col} key={col.field} body={dataTemplate} />;
        }
        if (col.field === "status") {
          return <Column {...col} key={col.field} body={statusTemplate} />;
        }
        return <Column {...col} key={col.field} body={centeredTemplate} />;
      });


      const columnsVehicle = this.props.vehicleColumns.map((col, index) => {
        return <Column {...col} key={col.field} />;
      })

      return (
        <div className="card-expanded">
          <div className="card-container">
            {currentFence && currentFence.length > 0 && (
              currentFence.map((item, index) => {
                return (
                  <Card key={`faces-${index}`}>
                    <h4>Cerca Virtual Atual</h4>
                    <p><strong>Nome:</strong> {item.name || ""}</p>
                    <p><strong>Tipo:</strong> {formatTextType(item.type) || ""}</p>
                    <p><strong>Data de Inclusão:</strong> {item.includeDate}</p>
                  </Card>
                )
              })
            )}

            {currentShipments && (
              currentShipments.map((item, index) => {
                return (
                  <Card key={`shipm-${index}`}>
                    <h4>Embarque Atual</h4>
                    <p><strong>Placa:</strong> {item.plate || ""}</p>
                    <p><strong>Cerca virtual:</strong> {item.virtualFenceName || ""}</p>
                    <p><strong>ID cerca virtual:</strong> {item.virtualFenceId || ""}</p>
                    <p><strong>Embarque:</strong> {item.id || ""}</p>
                    <p><strong>Data de Criação:</strong> {item.createdAt || ""}</p>
                    <p><strong>Data de Finalização:</strong> {item.dateFinish || ""}</p>
                  </Card>
                )
              })
            )}
          </div>

          <h3>Histórico de cercas virtuais</h3>
          <div className="p-grid" style={{ padding: '20px' }}>
            <DataTable
              className="lxSubDriverTable"
              selectionMode="single"
              value={historicoCerca}
              emptyMessage="Não foram encontrados registros"
            >
              {historyVirtualFenceColumnsSub}
            </DataTable>
          </div>

          <h3>Histórico de Embarques </h3>
          <div className="p-grid" style={{ padding: '20px' }}>
            <DataTable
              className="lxSubDriverTable"
              selectionMode="single"
              value={historicoEmbar}
              emptyMessage="Não foram encontrados registros"
            >
              {historyCargoShipmentColumnsSub}
            </DataTable>
          </div>

          <h3>Veículos </h3>
          <div className="p-grid" style={{ padding: '20px' }}>
            <DataTable
              className="lxSubDriverTable"
              selectionMode="single"
              value={this.props.vehicles}
              emptyMessage="Não foram encontrados registros"
            >
              {columnsVehicle}
            </DataTable>
          </div>
        </div>
      );

    };


    const content = (
      <div>

        <DriverList
          {...this.props}
          onFilter={this.onFilter}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
          driverTbRowToggleHandler={this.setExpandedDriverRows}
          driverTbRowExpasionTemplateHandler={templateDriverTbExpandedRow}
          driverTbExpandedRows={[this.props.recordExpand]}
        />
      </div>);
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverListPageContainer);
